import { GroupInfoElementProp, GroupInfoVideoElementProp, HotspotProp, ProductGroupsProp } from "./datastructureV2";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

const sys10_Free_Mirror: HotspotProp = {
    coordinates: [50.5, 26],
    media:[
        "/images/details/sys10_Free/Mirror/IMG_0013.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegel mit LED-Beleuchtung",
            descr: "und Spiegelheizung \nSIJN075",
        },
        {
            language: "en",
            title: "Illuminated mirror",
            descr: "incl. mirror heating \nSIJN075",
        },
        {
            language: "fr",
            title: "Miroir avec éclairage LED",
            descr: "incl. anti-buée \nSIJN075",
        },
        {
            language: "nl",
            title: "Lichtspiegel",
            descr: "incl. spiegelverwarming \nSIJN075",
        },
    ],
};

const sys10_Free_CompactWashbasin: HotspotProp = {
    coordinates: [40, 54],
    media: [
        "/images/details/sys10_Free/CompactWashbasin/IMG_0010.jpg",
        "/images/details/sys10_Free/CompactWashbasin/IMG_0011.jpg",
        "/images/details/sys10_Free/CompactWashbasin/IMG_0012.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Compact-Waschtisch",
            descr: "Waschtischfarbe: Marmor Schwarz \nSGCN102",
        },
        {
            language: "en",
            title: "Compact-washbasin",
            descr: "Washbasin colour: Black Marble \nSGCN102",
        },
        {
            language: "fr",
            title: "Plan de toilette Compact",
            descr: "Coloris de plan de toilette: Marbre noir \nSGCN102",
        },
        {
            language: "nl",
            title: "Wastafel compact",
            descr: "Wastafelkleur: Marmer zwart \nSGCN102",
        },
    ],
};

const sys10_Free_MineralCastWashbasin: HotspotProp = {
    coordinates: [50.5, 64],
    media: [
        "/images/details/sys10_Free/MineralCastWashbasin/IMG_0007.jpg",
        "/images/details/sys10_Free/MineralCastWashbasin/IMG_0008.jpg",
        "/images/details/sys10_Free/MineralCastWashbasin/IMG_0009.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Waschtischunterschrank",
            descr: 'Grifflos, wird durch Frontüberstand geöffnet \nFrontfarbe: Blau Matt \nWWKC100 / ACGK100',
        },
        {
            language: "en",
            title: "Vanity unit",
            descr: 'No handle, opens with overhang \nFrontcolour: Blue Matt \nWWKC100 / ACGK100',
        },
        {
            language: "fr",
            title: "Meuble sous-vasque",
            descr: 'sans poignée, s\'ouvre par le débord \nCouleur de façade: Bleu mat \nWWKC100 / ACGK100',
        },
        {
            language: "nl",
            title: "Wastafelonderkast",
            descr: 'Greeplos, wordt geopend m.b.v. overstekende front \nFrontkleur: Blauw mat \nWWKC100 / ACGK100',
        },
    ],
};

const sys10_Free_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys10_Free/productVideo/free.mp4"],
    title: groupInfo_productVideo,
};

const sys10_Free_assemblyVideo: GroupInfoVideoElementProp = {
    url: [
        "/videos/sys10_Free/montageVideo/free_wtu_montagefilm.mp4",
    ],
    title: groupInfo_assemblyVideo,
}


const sys10_Free_productOverview: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/productOverview/PU_Free_EN.pdf',
        title: "Produktdetails",
    },
    {
        language: "en",
        url: '/productOverview/PU_Free_EN.pdf',
        title: "Product details",
    },
    {
        language: "fr",
        url: '/productOverview/PU_Free_FR.pdf',
        title: "Détails du produit",
    },
    {
        language: "nl",
        url: '/productOverview/PU_Free_EN.pdf',
        title: "Productdetails",
    },
];

const sys10_Free_catalogue: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/catalog/KA_Free_FR.pdf',
        title: "Katalog",
    },
    {
        language: "en",
        url: '/catalog/KA_Free_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "fr",
        url: '/catalog/KA_Free_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "nl",
        url: '/catalog/KA_Free_FR.pdf',
        title: "Katalog",
    },
];

///////////// ProductGroup

export const sys10_Free: ProductGroupsProp = {
    id: 1,
    prevViewed: false,
    image: "/images/details/sys10_Free/IMG_0003.jpg",
    productGroupName: "sys10 Free",
    videos: [
        "/videos/sys10_Free/Moebelstueck3_in.mp4",
        "/videos/sys10_Free/Moebelstueck3_out.mp4"
    ],
    descr: "Free",
    hotspots: [
        sys10_Free_Mirror,
        sys10_Free_CompactWashbasin,
        sys10_Free_MineralCastWashbasin,
    ],
    groupInfo: {
        productOverview: sys10_Free_productOverview,
        catalog: sys10_Free_catalogue,
        productVideo: sys10_Free_productVideo,
        assemblyVideo: sys10_Free_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys10_Free/",
    },
    wall: "A2",
};