import { HotspotProp, ProductGroupsProp, GroupInfoVideoElementProp, } from "./datastructureV2";
import { sys20_Max_catalogue, sys20_Max_productOverview } from "./sys20_Max1";
import { sys20_Max2_Mirror } from "./sys20_Max2";

export const sys20_Max3_Mirror: HotspotProp = sys20_Max2_Mirror;

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Max3_CeramicWashbasin: HotspotProp = {
    coordinates: [50.5, 70],
    media: [
        "/images/details/sys20_Max3/CeramicWashbasin/max_700_eiche_wand_wt_1.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Keramik-Waschtisch",
            descr: "inkl. Waschtischunterschrank \nFrontfarbe: Asteiche Natur \nSGJX070",
        },
        {
            language: "en",
            title: "Ceramic washbasin",
            descr: "incl. vanity unit \nFrontcolour: Natural knotty oak \nSGJX070",
        },
        {
            language: "fr",
            title: "Plan de toilette en céramique",
            descr: "incl. meuble sous-vasque \nCouleur de façade: Chêne nature noueux \nSGJX070",
        },
        {
            language: "nl",
            title: "Wastafel keramiek",
            descr: "incl. wastafelonderkast \nFrontkleur: Eiken natuur knoestig \nSGJX070",
        },
    ],
};

export const sys20_Max3_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Max1/productVideo/max.mp4"],
    title: groupInfo_productVideo,
};

export const sys20_Max3_assemblyVideo: GroupInfoVideoElementProp = {
    url: [
        "/videos/sys20_Max1/montageVideo/max700_sps_montagefilm.mp4",
        "/videos/sys20_Max1/montageVideo/max700_wtu_montagefilm.mp4",
    ],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys20_Max3: ProductGroupsProp = {
    id: 5,
    prevViewed: false,
    image: "/images/details/sys20_Max3/IMG_0001.jpg",
    productGroupName: "sys20_Max3",
    videos: [
        "/videos/sys20_Max3/Moebelstueck7_in.mp4",
        "/videos/sys20_Max3/Moebelstueck7_out.mp4"
    ],
    descr: "Max",
    hotspots: [
        sys20_Max3_Mirror,
        sys20_Max3_CeramicWashbasin,
    ],
    groupInfo: {
        productOverview: sys20_Max_productOverview,
        catalog: sys20_Max_catalogue,
        productVideo: sys20_Max3_productVideo,
        assemblyVideo: sys20_Max3_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Max3/",
    },
    wall: "B2"
};