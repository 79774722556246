import { HotspotProp, ProductGroupsProp, GroupInfoVideoElementProp, } from "./datastructureV2";
import { sys20_Lin20_productOverview, sys20_Lin20_catalogue } from "./sys20_Lin20_1";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Lin20_2_Mirror: HotspotProp = {
    coordinates: [49.5, 22],
    media: [
        "/images/details/sys20_Lin20_2/Mirror/lin20_frassino-cappuchino_sps.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegelschrank mit LED-Beleuchtung",
            descr: "SPQK120",
        },
        {
            language: "en",
            title: "Mirror cabinet with LED-lighting",
            descr: "SPQK120",
        },
        {
            language: "fr",
            title: "Armoire de toilette avec éclairage LED",
            descr: "SPQK120",
        },
        {
            language: "nl",
            title: "Spiegelkasten met LED-verlichting",
            descr: "SPQK120",
        },
    ],
};


export const sys20_Lin20_2_MineralCastWashbasin: HotspotProp = {
    coordinates: [49.5, 67],
    media: [
        "/images/details/sys20_Lin20_2/MineralCastWashbasin/lin20_frassino-cappuchino_dwt.jpg",
        "/images/details/sys20_Lin20_2/MineralCastWashbasin/lin20_frassino-cappuchino_schublade.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Mineralguss-Waschtisch",
            descr: "inkl. Waschtischunterschrank \nFrontfarbe:Frassino Dekor Cappuccino \nSGHV122",
        },
        {
            language: "en",
            title: "Mineral cast washbasin",
            descr: "incl. vanity unit \nFrontcolour: Frassino cappuccino decor \nSGHV122",
        },
        {
            language: "fr",
            title: "Plan de toilette en pierre de synthèse",
            descr: "incl. meuble sous-vasque \nCouleur de façade: Frassino décor cappuccino \nSGHV122",
        },
        {
            language: "nl",
            title: "Wastafel mineraalsteen",
            descr: "incl. wastafelonderkast \nFrontkleur: Frassino decor cappuccino \nSGHV122",
        },
    ],
};

export const sys20_Lin20_2_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Lin20_1/productVideo/lin_20.mp4"],
    title: groupInfo_productVideo,
};

export const sys20_Lin20_2_assemblyVideo: GroupInfoVideoElementProp = {
    url: [],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys20_Lin20_2: ProductGroupsProp = {
    id: 9,
    prevViewed: false,
    image: "/images/details/sys20_Lin20_2/IMG_0001.jpg",
    productGroupName: "sys20_Lin20_2",
    videos: [
        "/videos/sys20_Lin20_2/Moebelstueck11_in.mp4",
        "/videos/sys20_Lin20_2/Moebelstueck11_out.mp4",
    ],
    descr: "Lin20",
    hotspots: [
        sys20_Lin20_2_Mirror,
        sys20_Lin20_2_MineralCastWashbasin,
    ],
    groupInfo: {
        productOverview: sys20_Lin20_productOverview,
        catalog: sys20_Lin20_catalogue,
        productVideo: sys20_Lin20_2_productVideo,
        assemblyVideo: sys20_Lin20_2_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Lin20_2/",
    },
    wall: "C1"
};