import * as React from 'react';
import { HotspotProp } from '../../../datastructure/datastructureV2';
import style from './hotspots.module.scss';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useEffect } from 'react';



export interface HotspotsProps {
    hotspots: Array<HotspotProp>;
    language: string;
    canvasImg?: any;
    onClick: (media: string[]) => void;
}

export function Hotspot({ coordinates, content, language, onClick }: HotspotProp) {
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [languageIndex, setLanguageIndex] = useState<number>(0)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setOpenPopover(true);
    }

    const handlePopoverClose = () => {
        setOpenPopover(false);
    }

    useEffect(() => {
        const langIndex = content.findIndex(ele => ele.language === language) ?? content.findIndex(ele => ele.language === 'de');
        setLanguageIndex(langIndex);
    }, [language]);

    const getButtonText = () => {
        let text: string = "View gallery";
        switch (language) {
            case "de":
                text = "Galerie ansehen";
                break;
            case "nl":
                text = "Bekijk galerij";
                break;
            case "fr":
                text = "Voir la galerie"
                break;
        }
        return text;
    }

    let buttonText = getButtonText();


    return (
        <div className={style.hotspot} style={{ left: coordinates[0] + '%', top: coordinates[1] + '%', position: 'absolute' }} onMouseLeave={handlePopoverClose}>
            <div
                className={style.hotspot_circle}
                key={content[0].title}
                onMouseOver={handlePopoverOpen}
                onClick={() => {document.documentElement.clientWidth > 900 && onClick!() }}
                onTouchStart={() => handlePopoverOpen}
                style={{ opacity: openPopover ? 1 : 0.7 }}
            />
            {openPopover &&
                <div
                    className={style.hotspot_popover_ele}
                    id="mouse-over-popover"
                    onMouseLeave={handlePopoverClose}
                >
                    <div className={style.hotspot_popover} onMouseLeave={handlePopoverClose} style={{ bottom: coordinates[1] >= 60 ? 0 : 'unset', right: coordinates[0] >= 60 ? 0 : 'unset'}}>
                        <p className={style.hotspot_title}>{content[languageIndex].title}</p>
                        <p className={style.hotspot_descr}>{content[languageIndex].descr}</p>
                        <Button
                            className={style.hotspot_button}
                            disableElevation
                            variant="contained"
                            //startIcon={<ImageIcon />}
                            onClick={() => {onClick!()}}
                        >
                            {buttonText}
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
}

function Hotspots({ hotspots, language, canvasImg, onClick }: HotspotsProps) {
    return (
        <div
            className={style.hotspots}
            style={{
                left: canvasImg?.xOffset ?? 0,
                top: canvasImg?.yOffset ?? 0,
                width: canvasImg?.newWidth ?? '100%',
                height: canvasImg?.newHeight ?? '100vh'
            }}
        >
            {
                hotspots.map((hotspot, index) => (
                    <Hotspot
                        coordinates={hotspot.coordinates}
                        content={hotspot.content}
                        media={hotspot.media}
                        key={hotspot.content[0].title + "_" + index}
                        onClick={() => onClick(hotspot.media)}
                        language={language}
                    />
                ))
            }
        </div>
    );
}

export default React.memo(Hotspots);