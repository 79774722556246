import { HotspotProp, ProductGroupsProp, GroupInfoVideoElementProp } from "./datastructureV2";
import { sys20_Max_productOverview, sys20_Max_catalogue } from "./sys20_Max1";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Max4_Mirror: HotspotProp = {
    coordinates: [50, 17],
    media: [
        "/images/details/sys20_Max4/Mirror/max_gaestebad_7.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegel mit LED-Ambientebeleuchtung",
            descr: "SIJQ045",
        },
        {
            language: "en",
            title: "Mirror with LED ambiance light",
            descr: "SIJQ045",
        },
        {
            language: "fr",
            title: "Miroir avec éclairage LED",
            descr: "SIJQ045",
        },
        {
            language: "nl",
            title: "Lichtspiegel met indirecte LED-verlichting",
            descr: "SIJQ045",
        },
    ],
};

export const sys20_Max4_CeramicWashbasins: HotspotProp = {
    coordinates: [50.3, 65],
    media: [
        "/images/details/sys20_Max4/CeramicWashbasins/max_gaestebad_6.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Keramik-Waschtisch",
            descr: "inkl. Waschtischunterschrank und Korb \nFrontfarbe: Asteiche Natur \nSGKA055",
        },
        {
            language: "en",
            title: "Ceramic washbasins sit on vessel",
            descr: "incl. vanity unit and basket \nFrontcolour: Natural knotty oak \nSGKA055",
        },
        {
            language: "fr",
            title: "Vasque à poser en céramique",
            descr: "incl. meuble sous-vasque et panier \nCouleur de façade: Chêne nature noueux \nSGKA055",
        },
        {
            language: "nl",
            title: "Keramische opzetwaskom",
            descr: "incl. wastafelonderkast en mand \nFrontkleur: Eiken natuur knoestig \nSGKA055",
        },
    ],
};

export const sys20_Max4_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Max1/productVideo/max.mp4"],
    title: groupInfo_productVideo,
};

export const sys20_Max4_assemblyVideo: GroupInfoVideoElementProp = {
    url: [
        "/videos/sys20_Max1/montageVideo/max700_sps_montagefilm.mp4",
        "/videos/sys20_Max1/montageVideo/max700_wtu_montagefilm.mp4",
    ],
    title: groupInfo_assemblyVideo,
}

export const sys20_Max4: ProductGroupsProp = {
    id: 6,
    prevViewed: false,
    image: "/images/details/sys20_Max4/IMG_0001.jpg",
    productGroupName: "sys20_Max4",
    videos: [
        "/videos/sys20_Max4/Moebelstueck8_in.mp4",
        "/videos/sys20_Max4/Moebelstueck8_out.mp4"
    ],
    descr: "Max",
    hotspots: [
        sys20_Max4_Mirror,
        sys20_Max4_CeramicWashbasins,
    ],
    groupInfo: {
        productOverview: sys20_Max_productOverview,
        catalog: sys20_Max_catalogue,
        productVideo: sys20_Max4_productVideo,
        assemblyVideo: sys20_Max4_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Max4/",
    },
    wall: "B2"
};