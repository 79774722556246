import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import style from './mediaPopup.module.scss';
import Fab from '@material-ui/core/Fab';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MobileStepper from '@material-ui/core/MobileStepper';
import { useState } from 'react';
import { useRef } from 'react';
import { Fade, makeStyles } from '@material-ui/core';

export interface MediaProps {
    url: string;
    playVideo: boolean;
}

function MediaComponent({ url, playVideo }: MediaProps) {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const video = videoRef.current;
    const fileType = url.substring(url.length - 4);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const stopVideo = () => {
        if (video?.paused === false) {
            video.pause();
            video.currentTime = 0;
        }
    }

    !playVideo && stopVideo();

    return (
        <div style={{ height: fileType === ".jpg" ? '100%' : 'auto', maxWidth: '70vw' }} >
            <Fade in={true}>
                <div>
                    <div>
                    {isLoading && <div className={style.loader} />}
                        {
                            fileType === ".jpg" ?
                                <img
                                    alt={url}
                                    src={url}
                                    className={style.imagePopup}
                                    onLoad={() => { setIsLoading(false)}}
                                    style={{opacity: isLoading ? 0 : 1}}
                                />
                                :
                                <video disablePictureInPicture className={style.videoplayerPopup}
                                    controls
                                    src={url}
                                    ref={videoRef}
                                    onCanPlay={() => setIsLoading(false)}
                                    style={{opacity: isLoading ? 0 : 1}}
                                ></video>
                        }
                    </div>
                </div>
            </Fade>
        </div>
    )
}

export interface MediaPopupProps {
    media: string[];
    open: boolean
    type: string;
    onClick: (open: boolean) => void;
}

function MediaPopup({ media, open, type, onClick }: MediaPopupProps) {
    const [mediaIndex, setMediaIndex] = useState<number>(0);
    const [playVideo, setPlayVideo] = useState<boolean>(false);

    const useStyles = makeStyles({
        root: {
            width: '100%',
            flexGrow: 2,
            background: 'transparent',
            justifyContent: 'center',
            "& .MuiMobileStepper-dots": {
                "& .MuiMobileStepper-dot": {
                    background: "rgba(255,255,255, 0.35)",
                },
                "& .MuiMobileStepper-dotActive": {
                    backgroundColor: 'white',
                },
            },
        },
    });

    const classes = useStyles();

    return (
        <Backdrop
            open={open}
            className={style.backdrop}
            style={{ zIndex: 20 }}
        >
            <div className={style.mediaPopup_container}>
                {media && media.map((item, itemIndex) => {
                    if (itemIndex === mediaIndex) {
                        return (
                            <MediaComponent
                                url={item}
                                key={item + "_" + itemIndex}
                                playVideo={playVideo}
                            />
                        )
                    } else {
                        return null;
                    }
                })
                }
                <Fab size="small" className={style.mediaPopup_fab} onClick={() => {
                    setPlayVideo(false);
                    mediaIndex !== 0 && setMediaIndex(0);
                    onClick(false);
                }}>
                    <CloseRoundedIcon />
                </Fab>
                <div className={style.actionContainer}>
                    {mediaIndex > 0 &&
                        <Fab size="small" className={style.mediaPopup_leftArrow} onClick={() => {
                            setMediaIndex(mediaIndex - 1);
                        }}>
                            <KeyboardArrowLeftIcon />
                        </Fab>
                    }
                    <MobileStepper
                        variant="dots"
                        steps={media.length}
                        position="static"
                        activeStep={mediaIndex}
                        className={classes.root}
                        style={{ 
                            maxHeight: '10px', 
                        marginLeft: mediaIndex === 0 ? '40px' : 0,
                        marginRight: (media.length - 1 === mediaIndex) ? '40px' : 0,
                    }}
                        nextButton={
                            <>
                            </>
                        }
                        backButton={
                            <>
                            </>
                        }
                    />
                    {media.length - 1 > mediaIndex &&
                        <Fab size="small" className={style.mediaPopup_rightArrow} onClick={() => {
                            setMediaIndex(mediaIndex + 1);
                        }}>
                            <KeyboardArrowRightIcon />
                        </Fab>
                    }

                </div>
            </div>
        </Backdrop>
    );
}

export default MediaPopup;