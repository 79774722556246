import { HotspotProp, ProductGroupsProp, GroupInfoVideoElementProp } from "./datastructureV2";
import { sys20_Lavo2_productOverview, sys20_Lavo2_catalogue } from "./sys20_Lavo2_1";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Lavo2_2_Mirror: HotspotProp = {
    coordinates: [51, 25],
    media: [
        "/images/details/sys20_Lavo2_2/Mirror/IMG_0004.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegel mit umlaufender LED-Beleuchtung",
            descr: "SIJH050",
        },
        {
            language: "en",
            title: "Mirror with circulating LED-light",
            descr: "SIJH050",
        },
        {
            language: "fr",
            title: "Miroir avec contour éclairage LED",
            descr: "SIJH050",
        },
        {
            language: "nl",
            title: "Lichtspiegel met omlopende LED-verlichting",
            descr: "SIJH050",
        },
    ],
};

export const sys20_Lavo2_2_MineralCastWashbasin: HotspotProp = {
    coordinates: [51, 68],
    media: [
        "/images/details/sys20_Lavo2_2/MineralCastWashbasin/IMG_0008.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Mineralguss-Aufsatzwaschtisch",
            descr: "MWGU050",
        },
        {
            language: "en",
            title: "Mineral cast washbasin sit on vessel",
            descr: "MWGU050",
        },
        {
            language: "fr",
            title: "Vasques à poser en pierre de synthèse",
            descr: "MWGU050",
        },
        {
            language: "nl",
            title: "Opzetwaskom mineraalsteen",
            descr: "MWGU050",
        },
    ],
};

export const sys20_Lavo2_2_VanityUnit: HotspotProp = {
    coordinates: [60, 81],
    media: [
        "/images/details/sys20_Lavo2_2/VanityUnit/IMG_0013.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Waschtischunterschrank",
            descr: 'Frontfarbe: Eiche Dekor Mokka \nGriff: Chrom G0226 \nSFZX121',
        },
        {
            language: "en",
            title: "Vanity unit",
            descr: 'Frontcolour: Mocca oak decor \nHandle: Chrome G0226 \nSFZX121',
        },
        {
            language: "fr",
            title: "Meuble sous-vasque",
            descr: 'Couleur de façade: Chêne décor Mokka \nPoignée: Chromé G0226 \nSFZX121',
        },
        {
            language: "nl",
            title: "Wastafelonderkast",
            descr: 'Frontkleur: Eiken decor mokka \nGreep: Chroom G0226 \nSFZX121',
        },
    ],
};


export const sys20_Lavo2_2_productVideo: GroupInfoVideoElementProp = {
    url: [],
    title: groupInfo_productVideo,
};

export const sys20_Lavo2_2_assemblyVideo: GroupInfoVideoElementProp = {
    url: [],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys20_Lavo2_2: ProductGroupsProp = {
    id: 1,
    prevViewed: false,
    image: "/images/details/sys20_Lavo2_2/IMG_0001.jpg",
    productGroupName: "sys20_Lavo",
    videos: [
        "/videos/sys20_Lavo2_2/Moebelstueck15_in.mp4",
        "/videos/sys20_Lavo2_2/Moebelstueck15_out.mp4"
    ],
    descr: "Lavo 2.0",
    hotspots: [
        sys20_Lavo2_2_Mirror,
        sys20_Lavo2_2_MineralCastWashbasin,
        sys20_Lavo2_2_VanityUnit,
    ],
    groupInfo: {
        productOverview: sys20_Lavo2_productOverview,
        catalog: sys20_Lavo2_catalogue,
        productVideo: sys20_Lavo2_2_productVideo,
        assemblyVideo: sys20_Lavo2_2_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Lavo2_2/",
    },
    wall: "D1"
};