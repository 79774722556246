import * as React from 'react';
import style from './introplayer.module.scss'
import Button from '@material-ui/core/Button';
import { useRef, useState, useEffect } from 'react';

export interface VideoplayerProps {
    videoUrl?: string | undefined;
    imageUrl?: string | undefined;
    introFab?: boolean | undefined;
    onEnded: () => void;
    language: string;
}

function Introplayer(props: VideoplayerProps) {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [startedIntro, setStartedIntro] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activeMedia, setActiveMedia] = useState<"video" | "image">("video");
    let logoPos = useRef<number>(videoRef && videoRef.current ? ((document.documentElement.clientWidth - videoRef.current.clientWidth) / 2) : 0);

    let buttonText = "Click here to start";

    switch (props.language) {
        case "de":
            buttonText = "Hier klicken um zu starten";
            break;
        case "fr":
            buttonText = "Cliquez ici pour commencer";
            break;
        case "nl":
            buttonText = "Klik hier om te starten";
            break;
        default:
            buttonText = "Click here to start";
            break;
    }


    const toggleVideo = () => {
        const video = videoRef.current;
        if (!video)
            return;
        if (video.paused) {
            video.play();
            setStartedIntro(true);
        }
        else
            video.pause();
    }

    useEffect(() => {
        const video = videoRef.current;
        if (!video)
            return;

        const handleResize = () => {
            logoPos.current = (document.documentElement.clientWidth - video.clientWidth) / 2;
        }

        video.addEventListener("ended", () => { })
        document.addEventListener("resize", handleResize)

        return () => {
            video.removeEventListener('ended', () => { })
            document.removeEventListener("resize", handleResize)
        }
    }, []);


    return (
        <div className={style.videoplayer}>


            {isLoading && <div className={style.loader} />}

            <div className={style.videoContainer}>
                {(document.documentElement.clientWidth > 950) &&
                    <img
                        draggable={false}
                        src={"/images/logo.png"}
                        alt={"Logo"}
                        className={style.videoplayer_image}
                        style={{ 
                            left: logoPos.current !== 0 ? logoPos.current : "3%", 
                            display: activeMedia === "video" ? 'block' : 'none',
                        }}
                    />
                }
                <video
                    disablePictureInPicture={true}
                    muted={true}
                    ref={videoRef}
                    className={activeMedia === "video" ? style.video : style.video_fade}
                    onEnded={props.onEnded}
                    onLoadedData={() => setIsLoading(false)}
                    autoPlay
                    loop
                    style={{ display: activeMedia === "video" ? 'block' : 'none' }}
                    src={props.videoUrl}
                >
                    Your browser does not support HTML video.
                </video>

            </div>

            <img
                src={props.imageUrl}
                alt="StartImg"
                style={{
                    opacity: activeMedia === "image" ? 1 : 0
                }}
                className={style.startPointimage}
            />

            {activeMedia === "video" &&
                <div
                    className={style.videoplayer_startActionContainer}
                    style={{
                        opacity: (props.introFab && !startedIntro && isLoading === false) ? 1 : 0,
                        pointerEvents: (props.introFab && !startedIntro && isLoading === false) ? 'auto' : 'none',
                    }}
                >
                    <Button className={style.fab} onClick={() => {
                        toggleVideo();
                        props.onEnded();
                        setActiveMedia("image");
                    }}
                    >
                        {buttonText}
                    </Button>

                </div>
            }
        </div>
    );
}

export default Introplayer;