import { HotspotProp, ProductGroupsProp, GroupInfoElementProp, GroupInfoVideoElementProp, } from "./datastructureV2";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Lin20_1_Mirror: HotspotProp = {
    coordinates: [51, 18],
    media: [
        "/images/details/sys20_Lin20_1/Mirror/IMG_0014.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegel mit umlaufender LED-Beleuchtung",
            descr: "SIJH050",
        },
        {
            language: "en",
            title: "Mirror with circulating LED-light",
            descr: "SIJH050",
        },
        {
            language: "fr",
            title: "Miroir avec contour éclairage LED",
            descr: "SIJH050",
        },
        {
            language: "nl",
            title: "Lichtspiegel met omlopende LED-verlichting",
            descr: "SIJH050",
        },
    ],
};

export const sys20_Lin20_1_MineralCastWashbasin: HotspotProp = {
    coordinates: [51, 71],
    media: [
        "/images/details/sys20_Lin20_1/MineralCastWashbasin/lin20_gaeste_rosa_2.jpg",
        "/videos/sys20_Lin20_1/MineralCastWashbasin/open.mp4",
        "/videos/sys20_Lin20_1/MineralCastWashbasin/close.mp4",
    ],
    content: [
        {
            language: "de",
            title: "Mineralguss-Waschtisch",
            descr: "inkl. Waschtischunterschrank \nFrontfarbe: Altrosa Supermatt \nSGHQ062",
        },
        {
            language: "en",
            title: "Mineral cast washbasin",
            descr: "incl. vanity unit \nFrontcolour: Old rose super matt \nSGHQ062",
        },
        {
            language: "fr",
            title: "Plan de toilette en pierre de synthèse",
            descr: "incl. meuble sous-vasque \nCouleur de façade: Vieux rose super mat \nSGHQ062",
        },
        {
            language: "nl",
            title: "Wastafel mineraalsteen",
            descr: "incl. wastafelonderkast \nFrontkleur: Oudroze super mat \nSGHQ062",
        },
    ],
};

//// Equal in all Lin20-Products
export const sys20_Lin20_productOverview: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/productOverview/PU_LIN20_DE.pdf',
        title: "Produktdetails",
    },
    {
        language: "en",
        url: '/productOverview/PU_LIN20_EN.pdf',
        title: "Product details",
    },
    {
        language: "fr",
        url: '/productOverview/PU_LIN20_FR.pdf',
        title: "Détails du produit",
    },
    {
        language: "nl",
        url: '/productOverview/PU_LIN20_NL.pdf',
        title: "Productdetails",
    },
];

//// Equal in all Fiomu-Products
export const sys20_Lin20_catalogue: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/catalog/KA_Lin20_DE.pdf',
        title: "Katalog",
    },
    {
        language: "en",
        url: '/catalog/KA_Lin20_EN.pdf',
        title: "Catalogue",
    },
    {
        language: "fr",
        url: '/catalog/KA_Lin20_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "nl",
        url: '/catalog/KA_Lin20_FL.pdf',
        title: "Katalog",
    },
];

export const sys20_Lin20_1_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Lin20_1/productVideo/lin_20.mp4"],
    title: groupInfo_productVideo,
};

export const sys20_Lin20_1_assemblyVideo: GroupInfoVideoElementProp = {
    url: [],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys20_Lin20_1: ProductGroupsProp = {
    id: 8,
    prevViewed: false,
    image: "/images/details/sys20_Lin20_1/IMG_0001.jpg",
    productGroupName: "sys20 Lin20",
    videos: [
        "/videos/sys20_Lin20_1/Moebelstueck10_in.mp4",
        "/videos/sys20_Lin20_1/Moebelstueck10_out.mp4",
    ],
    descr: "Lin20",
    hotspots: [
        sys20_Lin20_1_Mirror,
        sys20_Lin20_1_MineralCastWashbasin,
    ],
    groupInfo: {
        productOverview: sys20_Lin20_productOverview,
        catalog: sys20_Lin20_catalogue,
        productVideo: sys20_Lin20_1_productVideo,
        assemblyVideo: sys20_Lin20_1_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Lin20_1/",
    },
    wall: "C1"
};