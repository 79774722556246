import * as React from 'react';
import style from './mobileBlocker.module.scss';

export interface DisplayBlockerProps {
    language: string;
}

function DisplayBlocker({ language }: DisplayBlockerProps) {
    let text = "Please switch to desktop view";

    if (["de", "ch", "at"].includes(language)) {
        text = "Bitte wechseln Sie zur Desktop-Ansicht"
    } else if (language === "nl") {
        text = "Schakel over naar de desktopweergave";
    } else if (language === "fr") {
        text = "Veuillez passer à la vue du bureau";
    } else if (language === "en") {
        text = "Please switch to desktop mode"
    }

    return (
        <div className={style.mobileBlocker}>
            <a href={"https://burgbad.de/"}>
                <img src={"https://burgbad.de/grafik/burgbad-logo.svg"} alt="logo" />
            </a>
            <p>{text}</p>
        </div>
    );
}

export default DisplayBlocker;