import { HotspotProp, ProductGroupsProp, GroupInfoVideoElementProp } from "./datastructureV2";
import { sys20_Max_productOverview, sys20_Max_catalogue } from "./sys20_Max1";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Max5_Mirror: HotspotProp = {
    coordinates: [42.3, 16],
    media: [
        "/images/details/sys20_Max5/Mirror/IMG_0013.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegel mit LED-Ambientebeleuchtung",
            descr: "und Hängeschrank \nSIJP060 / OSKC030",
        },
        {
            language: "en",
            title: "Mirror with LED ambiance light",
            descr: "and wall unit \nSIJP060 / OSKC030",
        },
        {
            language: "fr",
            title: "Miroir avec éclairage LED",
            descr: "et meuble haut \nSIJP060 / OSKC030",
        },
        {
            language: "nl",
            title: "Lichtspiegel met indirecte led-verlichting",
            descr: "en hangkast \nSIJP060 / OSKC030",
        },
    ],
};

export const sys20_Max5_CeramicWashbasin: HotspotProp = {
    coordinates: [41.3, 62],
    media: [
        "/images/details/sys20_Max5/CeramicWashbasin/max_700_eiche_dark_detail_01.jpg",
        "/images/details/sys20_Max5/CeramicWashbasin/max_1000_eiche_dark_flaechenwt.jpg",
        "/images/details/sys20_Max5/CeramicWashbasin/max_1000_eiche_dark_hs_3.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Keramik-Waschtisch",
            descr: "inkl. Waschtischunterschrank \nFrontfarbe: Eiche Dark Choclate \nSGJV100",
        },
        {
            language: "en",
            title: "Ceramic washbasin",
            descr: "incl. vanity unit \nFrontcolour: Dark choclate oak \nSGJV100",
        },
        {
            language: "fr",
            title: "Plan de toilette en céramique",
            descr: "incl. meuble sous-vasque \nCouleur de façade: Chêne foncé choclat \nSGJV100",
        },
        {
            language: "nl",
            title: "Wastafel keramiek",
            descr: "incl. wastafelonderkast \nFrontkleur: Eiken donker chocola \nSGJV100",
        },
    ],
};

export const sys20_Max5_Stool: HotspotProp = {
    coordinates: [71, 79],
    media: [
        "/images/details/sys20_Max5/Stool/max_1000_eiche_dark_hocker_1.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Hocker",
            descr: "BANI045",
        },
        {
            language: "en",
            title: "Stool",
            descr: "BANI045",
        },
        {
            language: "fr",
            title: "Tabouret",
            descr: "BANI045",
        },
        {
            language: "nl",
            title: "Hocker",
            descr: "BANI045",
        },
    ],
};

export const sys20_Max5_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Max1/productVideo/max.mp4"],
    title: groupInfo_productVideo,
};

export const sys20_Max5_assemblyVideo: GroupInfoVideoElementProp = {
    url: [
        "/videos/sys20_Max1/montageVideo/max700_sps_montagefilm.mp4",
        "/videos/sys20_Max1/montageVideo/max700_wtu_montagefilm.mp4",
    ],
    title: groupInfo_assemblyVideo,
}

export const sys20_Max5: ProductGroupsProp = {
    id: 7,
    prevViewed: false,
    image: "/images/details/sys20_Max5/IMG_0001.jpg",
    productGroupName: "sys20_Max5",
    videos: [
        "/videos/sys20_Max5/Moebelstueck9_in.mp4",
        "/videos/sys20_Max5/Moebelstueck9_out.mp4"
    ],
    descr: "Max",
    hotspots: [
        sys20_Max5_Mirror,
        sys20_Max5_CeramicWashbasin,
        sys20_Max5_Stool,
    ],
    groupInfo: {
        productOverview: sys20_Max_productOverview,
        catalog: sys20_Max_catalogue,
        productVideo: sys20_Max5_productVideo,
        assemblyVideo: sys20_Max5_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Max5/",
    },
    wall: "B2"
};