import { GroupInfoElementProp, GroupInfoVideoElementProp, HotspotProp, ProductGroupsProp } from "./datastructureV2";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys10_Fiumo_1_TallUnit: HotspotProp = {
    coordinates: [12.5, 28],
    media: [
        "/images/details/sys10_Fiumo_1/TallUnit/IMG_0013.jpg",
        "/videos/sys10_Fiumo_1/TallUnit/open.mp4",
        "/videos/sys10_Fiumo_1/TallUnit/close.mp4",
    ],
    content: [
        {
            language: "de",
            title: "Hochschrank",
            descr: "Tür oben inkl. Tip-On-Technik \nHSLX035",
        },
        {
            language: "en",
            title: "Tall unit",
            descr: "door top with tip-on-technic and pleat-effect structure \nHSLX035",
        },
        {
            language: "fr",
            title: "Colonne",
            descr: "porte en haut avec tip-on \nHSLX035",
        },
        {
            language: "nl",
            title: "Hoge kast",
            descr: "deur bovenaan incl. tip-on-techniek \nHSLX035",
        },
    ],
};

export const sys10_Fiumo_1_MirrorCabinet: HotspotProp = {
    coordinates: [64.5, 18.5],
    media: [
        "/images/details/sys10_Fiumo_1/MirrorCabinet/IMG_0008.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegelschrank",
            descr: "inkl LED-Waschtischbeleuchtung \nSPIZ140",
        },
        {
            language: "en",
            title: "Mirror cabinet",
            descr: "incl. LED-lighting of washbasin \nSPIZ140",
        },
        {
            language: "fr",
            title: "Armoire de toilette",
            descr: "incl. eclairage LED du plan de toilette \nSPIZ140",
        },
        {
            language: "nl",
            title: "Spiegelkast",
            descr: "incl. LED-wastafelverlichting \nSPIZ140",
        },
    ],
};

export const sys10_Fiumo_1_MineralCastWashbasin: HotspotProp = {
    coordinates: [63, 68],
    media: [
        "/images/details/sys10_Fiumo_1/MineralCastWashbasin/fiumo_weiss-eiche_detail_front.jpg",
        "/images/details/sys10_Fiumo_1/MineralCastWashbasin/fiumo_weiss-eiche_doppelwt.jpg",
        "/images/details/sys10_Fiumo_1/MineralCastWashbasin/fiumo_weiss-eiche_inneneinteilung.jpg",
        "/videos/sys10_Fiumo_1/MineralCastWashbasin/open.mp4",
        "/videos/sys10_Fiumo_1/MineralCastWashbasin/close.mp4",
    ],
    content: [
        {
            language: "de",
            title: "Mineralguss-Waschtisch",
            descr: 'inkl. Waschtischunterschrank mit Griffleiste und Plisséefront \nFrontfarbe: Weiß Matt / Eiche Dekor Cashmere \nGriff: Schwarz Matt \nSGGS142',
        },
        {
            language: "en",
            title: "Mineral cast washbasin",
            descr: 'incl. vanity unit with handle and pleat-effect structure \nFrontcolour: White matt/Cashmere oak decor \nHandle: Black matt \nSGGS142',
        },
        {
            language: "fr",
            title: "Plan de toilette en pierre de synthèse",
            descr: 'incl. meuble sous-vasque avec prise de main et façade plissée \nCouleur de façade: Blanc mat/Chêne décor cachemire \nPoignée: Noir mat \nSGGS142',
        },
        {
            language: "nl",
            title: "Wastafel mineraalsteen",
            descr: 'incl. wastafelonderkast met greeplijst en plooifront \nFrontkleur: Wit mat/Eiken decor kasjmier \nGreep: Zwart mat \nSGGS142',
        },
    ],
};

//// Equal in all Fiumo-Products
export const sys10_Fiumo_productOverview: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/productOverview/PU_Fiumo_DE.pdf',
        title: "Produktdetails",
    },
    {
        language: "en",
        url: '/productOverview/PU_Fiumo_EN.pdf',
        title: "Product details",
    },
    {
        language: "fr",
        url: '/productOverview/PU_Fiumo_FR.pdf',
        title: "Détails du produit",
    },
    {
        language: "nl",
        url: '/productOverview/PU_Fiumo_NL.pdf',
        title: "Productdetails",
    },
];

//// Equal in all Fiumo-Products
export const sys10_Fiumo_catalogue: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/catalog/KA_Fiumo_DE.pdf',
        title: "Katalog",
    },
    {
        language: "en",
        url: '/catalog/KA_Fiumo_DE.pdf',
        title: "Catalogue",
    },
    {
        language: "fr",
        url: '/catalog/KA_Fiumo_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "nl",
        url: '/catalog/KA_Fiumo_NL.pdf',
        title: "Katalog",
    },
];

export const sys10_Fiumo_1_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys10_Fiumo_1/productVideo/fiumo.mp4"],
    title: groupInfo_productVideo,
};

export const sys10_Fiumo_1_assemblyVideo: GroupInfoVideoElementProp = {
    url: [
        "/videos/sys10_Fiumo_1/montageVideo/fiumo_sps_montagefilm.mp4",
        "/videos/sys10_Fiumo_1/montageVideo/fiumo_wtu_montagefilm.mp4",
    ],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys10_Fiumo_1: ProductGroupsProp = {
    id: 1,
    prevViewed: false,
    image: "/images/details/sys10_Fiumo_1/IMG_0001.jpg",
    productGroupName: "sys10 Fiumo",
    videos: [
        "/videos/sys10_Fiumo_1/Moebelstueck1_in.mp4",
        "/videos/sys10_Fiumo_1/Moebelstueck1_out.mp4"
    ],
    descr: "Fiumo",
    hotspots: [
        sys10_Fiumo_1_TallUnit,
        sys10_Fiumo_1_MirrorCabinet,
        sys10_Fiumo_1_MineralCastWashbasin,
    ],
    groupInfo: {
        productOverview: sys10_Fiumo_productOverview,
        catalog: sys10_Fiumo_catalogue,
        productVideo: sys10_Fiumo_1_productVideo,
        assemblyVideo: sys10_Fiumo_1_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys10_Fiumo_1/",
    },
    wall: "A1",
};