import React from 'react';
import './App.css';
import Main from './components/main';
import { ThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import { dataV2 } from './datastructure/datastructureV2';
import { useEffect } from 'react';
import { useState } from 'react';
//import classes from '*.module.css';
//import { Dialog, DialogTitle, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';

const theme = unstable_createMuiStrictModeTheme();

function App() {
  //const [openDialog, setOpenDialog] = useState<boolean>(true);
  const [countryCode, setCountryCode] = useState<string>('DE');
  let language = navigator.language.slice(0, 2);

  if (language !== "en" && language !== "de" && language !== "fr" && language !== "nl")
    language = "en";

  
  useEffect(() => {
    fetch('https://extreme-ip-lookup.com/json/')
      .then(res => res.json())
      .then(response => {
        setCountryCode(response.countryCode);
      })
      .catch((data) => {
        console.error('Request failed:', data);
      });
  }, [])
  

  const langData = dataV2.find(d => d.region === countryCode) ?? dataV2.find(d => d.region === 'DE');

  let introUrl: string = "";

  if (language === "de") {
    introUrl = "/videos/intro/Burgbad_Intro_v11_de_compressed.mp4";
  }
  else if (language === "en") {
    introUrl = "/videos/intro/Burgbad_Intro_v11_eng_compressed.mp4";
  }
  else if (language === "nl") {
    introUrl = "/videos/intro/Burgbad_Intro_v11_ned_compressed.mp4";
  }
  else if (language === "fr") {
    introUrl = "/videos/intro/Burgbad_Intro_v11_fra_compressed.mp4";
  }

  return (
    <div className="App">
      {/*
        <Dialog aria-labelledby="simple-dialog-title" open={openDialog}>
          <DialogTitle id="simple-dialog-title">Wählen Sie eine Region aus</DialogTitle>
          <List>
            <ListItem button onClick={() => { setCountryCode("DE"); setOpenDialog(false) }} >
              <ListItemAvatar>
                <Avatar src={"https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg"} />
              </ListItemAvatar>
              <ListItemText primary={"Deutschland"} />
            </ListItem>
            <ListItem button onClick={() => { setCountryCode("AT"); setOpenDialog(false) }} >
              <ListItemAvatar>
                <Avatar src={"https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_Austria.svg/2000px-Flag_of_Austria.svg.png"} />
              </ListItemAvatar>
              <ListItemText primary={"Österreich"} />
            </ListItem>
            <ListItem button onClick={() => { setCountryCode("CH"); setOpenDialog(false) }} >
              <ListItemAvatar>
                <Avatar src={"https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg"} />
              </ListItemAvatar>
              <ListItemText primary={"Schweiz"} />
            </ListItem>
            <ListItem button onClick={() => { setCountryCode("BE"); setOpenDialog(false) }} >
              <ListItemAvatar>
                <Avatar src={"https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg"} />
              </ListItemAvatar>
              <ListItemText primary={"Belgien"} />
            </ListItem>
            <ListItem button onClick={() => { setCountryCode("NL"); setOpenDialog(false) }} >
              <ListItemAvatar>
                <Avatar src={"https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg"} />
              </ListItemAvatar>
              <ListItemText primary={"Niederlande"} />
            </ListItem>
            <ListItem button onClick={() => { setCountryCode("FR"); setOpenDialog(false) }} >
              <ListItemAvatar>
                <Avatar src={"https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg"} />
              </ListItemAvatar>
              <ListItemText primary={"Frankreich"} />
            </ListItem>
          </List>
        </Dialog>
      */}
      
      {/* !openDialog && */
        <ThemeProvider theme={theme}>
          <Main data={langData!} language={language} introVideo={introUrl!} />
        </ThemeProvider>
      }

    </div>
  );
}

export default App;