import { CSSProperties, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { getWindowDimensions } from '../../../utils';
import path from 'path';

export interface UseFake3DProps {
    frames: number;
    url: string;
    canvasRef: MutableRefObject<HTMLCanvasElement | null>;
    contextRef: MutableRefObject<CanvasRenderingContext2D | null>;
    currDragged?: (active: boolean) => void;
    getCanvasImage?: (img: { xOffset: number, yOffset: number, newWidth: number, newHeight: number }) => void;
    zoomPos: {
        x: number;
        y: number;
        active: boolean;
    }
    deactivate3D: boolean;
    setLoading: (active: boolean) => void;
}

export interface DragPointProps {
    x: number;
    y: number;
    currDrag: boolean;
}

const currentFrame = (url: string, index: number) => {
    const ceiled = Math.ceil(index);
    const fileName = `IMG_${ceiled.toString().padStart(5, '0')}.jpg`;
    return path.join(url, fileName);
}

/*
function easeOutQuad(x: number): number {
    return 1 - (1 - x) * (1 - x);
}

const convertPercentToPixel = (percentX: number, percentY: number, canvasWidth: number, canvasHeight: number) => {
    const pixelX = canvasWidth * percentX / 100;
    const pixelY = canvasHeight * percentY / 100;

    return ({ pixelX, pixelY })
}
*/

const useFake3D = ({ frames, url, canvasRef, contextRef, currDragged, getCanvasImage, zoomPos, deactivate3D, setLoading }: UseFake3DProps) => {
    const imgRef = useRef<HTMLImageElement>(new Image());
    const dragStartPointRef = useRef<DragPointProps>({ x: 0, y: 0, currDrag: false });
    const img = imgRef.current;
    const [canvasImg, setCanvasImg] = useState<{ xOffset: number, yOffset: number, newWidth: number, newHeight: number }>({
        xOffset: 0, yOffset: 0, newWidth: 0, newHeight: 0,
    });
    let currentDisplayedImgIndex = useRef<number>(23);

    //const [savedImgs, setSavedImgs] = useState<string[]>();

    const canvas = canvasRef.current;
    let style: CSSProperties = {};
    if (canvas) {
        // const hotspot = convertPercentToPixel(zoomPos.x, zoomPos.y, canvas.width, canvas.height);
        style = {
            transformOrigin: `${zoomPos.x}% ${zoomPos.y}%`,
            transform: `scale(${zoomPos.active ? 1.5 : 1})`,
            transition: 'transform 200ms ease-out'
        }
    }

    const preloadImages = useRef<HTMLImageElement[]>([]);

    const cacheImages = useCallback(() => {
        const imgFront = new Image();
        imgFront.src = currentFrame(url, frames / 2 + 2);
        preloadImages.current[(frames / 2) - 1] = imgFront;
        imgFront.onload = () => {
            for (let i = 1; i < frames + 1; i++) {
                if (((frames / 2) - 1) !== i) {
                    const img = new Image();
                    img.src = currentFrame(url, i);
                    preloadImages.current[i - 1] = img;
                }
            }

        }

    }, [frames, url])

    useEffect(() => {
        return () => {
            preloadImages.current.forEach(img => img.src = '')
            preloadImages.current = []
        }
    }, [frames, url])

    /*
    const saveImages = useCallback(() => {
        const cache = cacheImages();
        const newCanvas = document.createElement('canvas');
        const newContext = newCanvas.getContext("2d");
        let storage: any = [];
        let tempIMG = new Image();

        cache.forEach((img) => {
            tempIMG.src = img;
            newContext?.drawImage(tempIMG, 0, 0, 0, 0)
            let base64 = newCanvas.toDataURL();
            storage.push(base64)
        })
        setSavedImgs(storage);
        console.log("savedImgs", savedImgs);
    }, [cacheImages, savedImgs])
    */

    const centerImg = useCallback((imgWidth: number, imgHeight: number) => {
        const canvas = canvasRef.current;
        if (canvas) {
            var wrh = imgWidth / imgHeight;
            var newWidth = canvas.width;
            var newHeight = newWidth / wrh;
            if (newHeight > canvas.height) {
                newHeight = canvas.height;
                newWidth = newHeight * wrh;
            }
            var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
            var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;

            return ({ xOffset, yOffset, newWidth, newHeight })
        }
    }, [canvasRef]);

    const onStartDrag = useCallback((event: MouseEvent | TouchEvent) => {
        if (canvasRef.current) {
            if (event instanceof MouseEvent) {
                dragStartPointRef.current = {
                    x: event.clientX - canvasRef.current.offsetLeft,
                    y: event.pageY - canvasRef.current.offsetTop,
                    currDrag: true,
                }
            } else if (event instanceof TouchEvent) {
                dragStartPointRef.current = {
                    x: event.touches[0].clientX - canvasRef.current.offsetLeft,
                    y: event.touches[0].pageY - canvasRef.current.offsetTop,
                    currDrag: true,
                }
            }
        }
        currDragged?.(true);
    }, [canvasRef, currDragged])

    const updateImage = useCallback((index: number) => {
        img.src = currentFrame(url, index);
        //const imageCache = cacheImages();
        //img.src = imageCache[Math.round(index)];
        const context = contextRef.current;

        if (context) {
            if (frames / 2 === index) {
                img.onloadstart = () => {
                    setLoading(true);
                }
                img.onended = () => {
                    setLoading(false);
                }
            }


            img.onload = () => {
                const resizedValues = centerImg(img.width, img.height)
                if (
                    canvasImg.xOffset !== resizedValues?.xOffset ||
                    canvasImg.yOffset !== resizedValues.yOffset ||
                    canvasImg.newHeight !== resizedValues.newHeight ||
                    canvasImg.newWidth !== resizedValues.newWidth) {
                    setCanvasImg(resizedValues!);
                    getCanvasImage!(resizedValues!);
                }
                if (resizedValues) {
                    context.drawImage(
                        img,
                        resizedValues.xOffset,
                        resizedValues?.yOffset,
                        resizedValues?.newWidth,
                        resizedValues?.newHeight
                    );
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [img, url, centerImg, contextRef])

    const handleResize = useCallback(() => {
        const canvas = canvasRef.current;
        const windowDimensions = getWindowDimensions();
        if (canvas) {
            canvas.width = windowDimensions.width;
            canvas.height = windowDimensions.height;
            updateImage(frames / 2)
        }
    }, [canvasRef, frames, updateImage])

    const reset = useCallback(() => {
        updateImage(frames / 2)
    }, [frames, updateImage])

    const onDrag = useCallback((event: MouseEvent | TouchEvent) => {
        if (!deactivate3D) {
            const canvas = canvasRef.current;
            const startingPoint = dragStartPointRef.current;

            if (startingPoint.currDrag && canvas) {
                let dragEndPoint = { x: 0, y: 0 };
                if (event instanceof MouseEvent) {
                    dragEndPoint = {
                        x: event.pageX - canvas.offsetLeft,
                        y: event.pageY - canvas.offsetTop
                    }
                } else if (event instanceof TouchEvent) {
                    dragEndPoint = {
                        x: event.touches[0].pageX - canvas.offsetLeft,
                        y: event.touches[0].pageY - canvas.offsetTop
                    }
                }

                const dragWindow = {
                    startPoint: startingPoint.x - 200,
                    endPoint: startingPoint.x + 200,
                    width: 400,
                }

                const windowFormat = dragWindow.width / frames;
                const mouseSpeedUp = 1.50;
                const moveXAxis = -100;
                const mouseValue = (dragWindow.width / getWindowDimensions().width * dragEndPoint.x * mouseSpeedUp) + moveXAxis;
                const frameValue = 1 / windowFormat;

                const frameIndex = Math.round(frameValue * mouseValue);
                currentDisplayedImgIndex.current = frameIndex;

                if (frameIndex <= frames && frameIndex > 0)
                    updateImage(frameIndex)
            }
        }
    }, [frames, updateImage, canvasRef])

    const onDragEnd = useCallback(() => {
        const startingPoint = dragStartPointRef.current;
        if (startingPoint.currDrag)
            dragStartPointRef.current = {
                x: startingPoint.x,
                y: startingPoint.y,
                currDrag: false,
            };
        reset();
        currDragged?.(false);
    }, [reset, currDragged]);

    useEffect(() => () => { img.onload = null }, [])

    return { cacheImages, onDrag, onStartDrag, onDragEnd, reset, updateImage, handleResize, style, canvasImg };
}

export default useFake3D;