import { HotspotProp, ProductGroupsProp, GroupInfoElementProp, GroupInfoVideoElementProp, } from "./datastructureV2";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Max1_WallUnit: HotspotProp = {
    coordinates: [58.7, 20],
    media: [
        "/images/details/sys20_Max1/WallUnit/IMG_0006.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Hängeschrank",
            descr: "OSKC030",
        },
        {
            language: "en",
            title: "Wall unit",
            descr: "OSKC030",
        },
        {
            language: "fr",
            title: "Meuble haut",
            descr: "OSKC030",
        },
        {
            language: "nl",
            title: "Hangkast",
            descr: "OSKC030",
        },
    ],
};

export const sys20_Max1_CommodeWithLongLineHandle: HotspotProp = {
    coordinates: [45.5, 48],
    media: [
        "/images/details/sys20_Max1/CommodeWithLongLineHandle/max_eiche_kommode_2.jpg",
        "/images/details/sys20_Max1/CommodeWithLongLineHandle/max_eiche_kommode_3.jpg",
        "/images/details/sys20_Max1/CommodeWithLongLineHandle/max_eiche_kommode_detail_.jpg",
        "/videos/sys20_Max1/CommodeWithLongLineHandle/open.mp4",
        "/videos/sys20_Max1/CommodeWithLongLineHandle/close.mp4",
    ],
    content: [
        {
            language: "de",
            title: "Kommode mit gefräster Griffleiste",
            descr: "inkl. 2 Körbe \nFrontfarbe: Asteiche Natur \nKOCA100",
        },
        {
            language: "en",
            title: "Commode with long line handle",
            descr: "incl. 2 baskets \nFrontcolour: Natural knotty oak \nKOCA100",
        },
        {
            language: "fr",
            title: "Commode avec prise de main",
            descr: "incl. 2 paniers \nCouleur de façade: Chêne nature noueux \nKOCA100",
        },
        {
            language: "nl",
            title: "Commode met greeplijst",
            descr: "incl. 2 manden \nFrontkleur: Eiken natuur knoestig \nKOCA100",
        },
    ],
};

//// Equal in all Max-Products
export const sys20_Max_productOverview: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/productOverview/PU_Max_DE.pdf',
        title: "Produktdetails",
    },
    {
        language: "en",
        url: '/productOverview/PU_Max_EN.pdf',
        title: "Product details",
    },
    {
        language: "fr",
        url: '/productOverview/PU_Max_FR.pdf',
        title: "Détails du produit",
    },
    {
        language: "nl",
        url: '/productOverview/PU_Max_NL.pdf',
        title: "Productdetails",
    },
];

//// Equal in all Max-Products
export const sys20_Max_catalogue: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/catalog/KA_Max_DE.pdf',
        title: "Katalog",
    },
    {
        language: "en",
        url: '/catalog/KA_Max_EN.pdf',
        title: "Catalogue",
    },
    {
        language: "fr",
        url: '/catalog/KA_Max_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "nl",
        url: '/catalog/KA_Max_FL.pdf',
        title: "Katalog",
    },
];

export const sys20_Max1_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Max1/productVideo/max.mp4"],
    title: groupInfo_productVideo,
};

export const sys20_Max1_assemblyVideo: GroupInfoVideoElementProp = {
    url: [
        "/videos/sys20_Max1/montageVideo/max700_sps_montagefilm.mp4",
        "/videos/sys20_Max1/montageVideo/max700_wtu_montagefilm.mp4",
    ],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys20_Max1: ProductGroupsProp = {
    id: 3,
    prevViewed: false,
    image: "/images/details/sys20_Max1/IMG_0001.jpg",
    productGroupName: "sys20 Max",
    videos: [
        "/videos/sys20_Max1/Moebelstueck5_in.mp4",
        "/videos/sys20_Max1/Moebelstueck5_out.mp4"
    ],
    descr: "Max",
    hotspots: [
        sys20_Max1_WallUnit,
        sys20_Max1_CommodeWithLongLineHandle,
    ],
    groupInfo: {
        productOverview: sys20_Max_productOverview,
        catalog: sys20_Max_catalogue,
        productVideo: sys20_Max1_productVideo,
        assemblyVideo: sys20_Max1_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Max1/",
    },
    wall: "B1"
};