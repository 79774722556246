import * as React from 'react';
import { Fake3DProps } from '../fake3D/fake3D';
import style from './videoImageTransition.module.scss'
import Fake3D from '../fake3D/fake3D';
import { useState, useEffect, useRef } from 'react';


export interface VideoImageTransitionProps extends Fake3DProps {
    videoUrls: [string, string];
    imageUrl: string;
    rotationUrl?: string | null;
    onPlay: (active: boolean) => void;
    getCanvasImage: (img: { xOffset: number, yOffset: number, newWidth: number, newHeight: number }) => void;
    deactivate3D: boolean;
    activateCoverImage: (active: boolean) => void;
}

function VideoImageTransition({ videoUrls, imageUrl, rotationUrl, onPlay, getCanvasImage, frames, url, currDragged, zoomPos, deactivate3D, activateCoverImage }: VideoImageTransitionProps) {
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [currentImgUrl, setCurrentImgUrl] = useState(imageUrl)
    const [outroSrc, setOutroSrc] = useState(undefined as undefined | string);
    const [outroPlaying, setOutroPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [rotationPlaying, setRotationPlaying] = useState(false);
    const [cover, setCover] = useState<boolean>(false)


    const introVideoElement = useRef<HTMLVideoElement>(null);
    const outroVideoElement = useRef<HTMLVideoElement>(null);
    const rotateVideoElement = useRef<HTMLVideoElement>(null);

    const onVideoStart = () => setVideoPlaying(true);
    const onIntroEndnd = () => setVideoPlaying(false);

    useEffect(() => {
        setVideoPlaying(true);
        setIsLoading(true);
        setCurrentImgUrl(imageUrl)
        const introVid = introVideoElement.current;
        const outroVid = outroVideoElement.current;
        const rotateVid = rotateVideoElement.current;
        var tempRotatePlay = rotationPlaying;

        if (!introVid || !outroVid || !rotateVid)
            return

        introVid.src = videoUrls[0];

        introVid.onloadstart = function () {
            //setIsLoading(true);
        }

        introVid.onended = function () {
            outroVid.src = videoUrls[1];
            onIntroEndnd();
            if (rotationUrl) {
                introVid.pause();
                rotateVid.src = rotationUrl;
            }

            if (!outroSrc && !tempRotatePlay) {
                setIsLoading(false)
            }

            activateCoverImage(true);
        }

        rotateVid.onloadstart = function () {
            //setIsLoading(true);
        }

        rotateVid.onended = function () {
            setRotationPlaying(false);
            introVid.play()
        }


        if (outroSrc && !tempRotatePlay) {
            introVid.onplaying = null;
            outroVid.onplaying = () => {
                onVideoStart();
                activateCoverImage(false);
            }
            outroVid.src = outroSrc;


            outroVid.oncanplaythrough = function () {
                setIsLoading(false);
            }

            outroVid.onended = function () {
                setOutroPlaying(false);
                outroVid.pause();

                if (rotationUrl) {
                    rotateVid.onplaying = onVideoStart;
                    setRotationPlaying(true);
                    rotateVid.play().catch(err => { console.warn('error playing vid', err) });
                }

                if (!rotationUrl)
                    introVid.play();
            }

            outroVid.play().catch(err => { console.warn('error playing vid', err) });
            setOutroPlaying(true);
        } else {
            introVid.onplaying = onVideoStart;
            introVid.play().catch(err => { console.warn('error playing vid', err) });
        }

        setOutroSrc(videoUrls[1]);
    }, [videoUrls, rotationUrl])


    useEffect(() => {
        onPlay && onPlay(videoPlaying);
    }, [videoPlaying])

    return (
        <div className={style.videoImageTransition}>
            {isLoading && <div className={style.loader} />}

            <div className={style.fake3D_Container} style={{ display: !isLoading && !videoPlaying ? 'block' : 'none' }} >
                <Fake3D
                    frames={frames}
                    url={currentImgUrl ?? ""}
                    currDragged={currDragged}
                    getCanvasImage={getCanvasImage}
                    zoomPos={zoomPos}
                    deactivate3D={deactivate3D}
                    cacheImgs={!isLoading && !videoPlaying ? true : false}
                />
            </div>

            {/*
            <img
                src={currentImgUrl + "IMG_00023.jpg"}
                alt={currentImgUrl}
                style={{display: cover && !isLoading && !videoPlaying ? 'block' : 'none'}}
            />
            */}
            <video
                disablePictureInPicture={true}
                preload="auto"
                ref={introVideoElement}
                muted={true}
                className={style.videoImageTransition_videoplayer}
                onClick={(e) => e.stopPropagation()}
                style={{
                    display: (!outroPlaying || !rotationPlaying) && videoPlaying ? 'block' : 'none',
                    zIndex: (!outroPlaying || !rotationPlaying) && (videoPlaying) ? 1 : -10,
                    position: (!outroPlaying || !rotationPlaying) && (videoPlaying) ? 'absolute' : 'unset',
                }}
            >
                Your browser does not support the video tag.
            </video>

            <video
                disablePictureInPicture={true}
                preload="auto"
                ref={outroVideoElement}
                muted={true}
                className={style.videoImageTransition_videoplayer}
                onClick={(e) => e.stopPropagation()}
                style={{
                    display: (outroPlaying || !rotationPlaying) && videoPlaying ? 'block' : 'none',
                    zIndex: videoPlaying && outroPlaying ? 10 : -10,
                    position: (outroPlaying || !rotationPlaying) && videoPlaying ? 'absolute' : 'unset'
                }}
            >
                Your browser does not support the video tag.
            </video>

            <video
                disablePictureInPicture={true}
                preload="auto"
                ref={rotateVideoElement}
                muted={true}
                src={rotationUrl!}
                className={style.videoImageTransition_videoplayer}
                onClick={(e) => e.stopPropagation()}
                style={{
                    display: rotationPlaying && rotationUrl && videoPlaying ? 'block' : 'none',
                    zIndex: rotationPlaying && videoPlaying ? 10 : -10,
                    position: rotationPlaying && rotationUrl && videoPlaying ? 'absolute' : 'unset'
                }}
            >
                Your browser does not support the video tag.
            </video>

        </div>
    );
}
export default React.memo(VideoImageTransition);