import * as React from 'react';
import { useState } from 'react';
import style from './main.module.scss'
import { DataProps, ProductGroupsProp } from '../datastructure/datastructureV2';
import Sidebar from './ui/sidebar/sidebar';
import InfoComponent from './ui/infoComponent/infoComponent';
import Hotspots from './ui/HotSpots/hotspots';
import { ZoomPosProps } from './ui/fake3D/fake3D';
import Introplayer from './ui/video-player/introplayer';
import VideoImageTransition from './ui/video-player/videoImageTransition';
import MobileBlocker from './ui/mobileBlocker/mobileBlocker';
import MediaPopup from './ui/MediaPopup/mediaPopup';
import { useEffect } from 'react';
import clsx from 'clsx'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Fab } from '@material-ui/core';
import { useRef } from 'react';

interface MainProps {
    data: DataProps;
    language: string;
    introVideo: string;
}


function Main({ data, language, introVideo }: MainProps) {
    const [currDragged, setCurrDragged] = useState<boolean>(false);
    const [mediaPopup, setMediaPopup] = useState({ open: false, url: [""] })
    const [zoomPos, setZoomPos] = useState<ZoomPosProps>({ x: 0, y: 0, active: false })
    const [selectedGroupIndex, setselectedGroupIndex] = useState<number | null>(null);
    const [productGroups, setProductGroups] = useState<Array<ProductGroupsProp>>(data.productGroups);
    const [introFinished, setIntroFinished] = useState<boolean>(false);
    const [onVideoPlay, setOnVideoPlay] = useState<boolean>(false);

    const [canvasImg, setCanvasImg] = useState<any>(undefined);
    const [rotationUrl, setRotationUrl] = useState<string | null>(null);
    const [openSidebar, setOpenSidebar] = useState<boolean>(false);
    const [currFrontImage, setCurrFrontImage] = useState<number>();
    const [cover, setCover] = useState<boolean>(false);

    let isFiomuSize = document.documentElement.clientWidth <= 1530 ? true : false;
    let deactivate3D = false;



    /*
    if (selectedGroupIndex)
        switch (productGroups[selectedGroupIndex!].productGroupName) {
            case "sys10_Fiumo2":
                deactivate3D = true;
                break;
            case "sys20_Max4":
                deactivate3D = true;
                break;
            case "sys20 Lin20":
                deactivate3D = true;
                break;
            default:
                deactivate3D = false;
                break;
        }
    */

    let visibleNav = true;
    if ((zoomPos.active || currDragged))
        visibleNav = false;


    const mainUiClass = onVideoPlay ? (document.documentElement.clientWidth >= 950 ? style.main_ui_remove : style.main_ui_Tabletremove) : style.main_ui;

    const handleMedia = (open: boolean, url?: string[]) => {
        setMediaPopup({ open: open, url: url ?? mediaPopup.url });
    }

    const handleProductGroups = (index: number) => {
        let pGs = productGroups;
        pGs[index].prevViewed = true;
        setselectedGroupIndex(index);
        setProductGroups(pGs);
    }

    const handleRotation = (oldWall: string, newWall: string) => {
        if (oldWall !== newWall) {
            setRotationUrl("/videos/rotation/" + oldWall + "_" + newWall + ".mp4");
        } else {
            setRotationUrl(null);
        }
    }

    useEffect(() => {
        setProductGroups(data.productGroups)
    }, [data])

    let introDisplay = useRef<string>('block');

    useEffect(() => {
        if (selectedGroupIndex !== null && introDisplay.current === 'block') {
            setTimeout(() => {
                introDisplay.current = 'none';
            }, 200)
        }
    }, [selectedGroupIndex])

    return (
        <>

            <MobileBlocker language={language} />

            <div className={style.main}>
                {(document.documentElement.clientWidth <= 950) &&
                    <div className={style.main_logo}>
                        <a onClick={() => window.location.reload()}>
                            <img src={"/images/logo.png"} alt="logo" />
                        </a>
                    </div>
                }

                <div className={selectedGroupIndex !== null ? style.main_introFade : style.main_intro} style={{ display: introDisplay.current }} >
                    <Introplayer
                        onEnded={() => setIntroFinished(true)}
                        videoUrl={introVideo}
                        introFab={true}
                        imageUrl={"/videos/intro/IMG_0044.jpg"}
                        language={language}
                    />

                </div>
                {introFinished &&
                    <>
                        < div className={style.main_productView}>
                            {selectedGroupIndex != null && selectedGroupIndex >= 0 &&
                                <div className={style.fake3DContainer} style={{ filter: mediaPopup.open ? 'blur(3px)' : 'none' }}>
                                    <VideoImageTransition
                                        videoUrls={productGroups[selectedGroupIndex].videos}
                                        frames={productGroups[selectedGroupIndex]._3D.frames}
                                        url={""}
                                        imageUrl={productGroups[selectedGroupIndex]._3D.url}
                                        currDragged={setCurrDragged}
                                        getCanvasImage={(img) => setCanvasImg(img)}
                                        zoomPos={zoomPos}
                                        onPlay={(active) => { setOnVideoPlay(active) }}
                                        rotationUrl={rotationUrl}
                                        deactivate3D={deactivate3D}
                                        activateCoverImage={(active) => {
                                            setCover(active);
                                        }}
                                    />
                                    {currFrontImage !== undefined &&
                                        <div className={style.stillImgContainer}>
                                            <img
                                                draggable={false}
                                                className={style.stillImg}
                                                style={{
                                                    height: '100vh',
                                                    opacity: cover ? 1 : 0,
                                                }}
                                                src={productGroups[currFrontImage]._3D.url + "IMG_00023.jpg"}
                                                alt={"still"}
                                            />
                                        </div>
                                    }
                                    {selectedGroupIndex != null && !onVideoPlay && !deactivate3D &&
                                        <div
                                            className={style.fake3DContainer_imgContainer}

                                            style={{
                                                opacity: !visibleNav || mediaPopup.open ? 0 : 0.5,
                                                bottom: ((document.documentElement.clientWidth <= 950) || (document.documentElement.clientWidth < document.documentElement.clientHeight)) ? 275 : 0,
                                                overflow: ((document.documentElement.clientWidth <= 950) || (document.documentElement.clientWidth < document.documentElement.clientHeight)) ? 'unset' : 'hidden',
                                                left: canvasImg?.xOffset ?? 0,
                                                top: canvasImg?.yOffset ?? 0,
                                                width: canvasImg?.newWidth ?? '100%',
                                                height: canvasImg?.newHeight ?? '100vh'
                                            }}
                                        >
                                            <img alt="dragIndicator" src={'/images/fake3D/dragIndicator/1.png'} />
                                        </div>
                                    }
                                </div>
                            }

                            <div
                                className={clsx(
                                    ((selectedGroupIndex !== null) && (productGroups[selectedGroupIndex!].productGroupName === "sys10 Fiumo") && isFiomuSize) && style.main_ui_fiomu,
                                    visibleNav ? mainUiClass : style.main_ui_drag)
                                }
                            >
                                <div
                                    style={(selectedGroupIndex !== null) && (productGroups[selectedGroupIndex!].productGroupName === "sys10 Fiumo") && openSidebar && isFiomuSize ? { transform: 'inherit' } : { display: 'block' }}
                                    onMouseLeave={() => { openSidebar && setOpenSidebar(false) }}
                                    className={clsx(style.sidebarContainer, onVideoPlay && style.sidebarDisabled)}
                                >
                                    <Sidebar
                                        productGroups={productGroups}
                                        selectedGroupIndex={selectedGroupIndex}
                                        onClick={(newIndex, productGroup) => {
                                            if (newIndex !== selectedGroupIndex) {
                                                if (selectedGroupIndex || selectedGroupIndex === 0) {
                                                    setCurrFrontImage(selectedGroupIndex);
                                                    handleRotation(productGroups[selectedGroupIndex].wall, productGroups[newIndex].wall);
                                                }
                                                handleProductGroups(newIndex);

                                                if (openSidebar)
                                                    setOpenSidebar(false);
                                            }
                                        }}
                                    />

                                </div>

                                {(selectedGroupIndex !== null) && (productGroups[selectedGroupIndex!].productGroupName === "sys10 Fiumo") && (openSidebar === false) && isFiomuSize &&
                                    <Fab className={style.icon_fiomu} onClick={() => { setOpenSidebar(true); }}> <ArrowForwardIosIcon /> </Fab>
                                }

                                {selectedGroupIndex != null && !onVideoPlay &&
                                    <>
                                        <InfoComponent
                                            productOverview={productGroups[selectedGroupIndex].groupInfo.productOverview}
                                            catalog={productGroups[selectedGroupIndex].groupInfo.catalog}
                                            productVideo={productGroups[selectedGroupIndex].groupInfo.productVideo}
                                            assemblyVideo={productGroups[selectedGroupIndex].groupInfo.assemblyVideo}
                                            language={language}
                                            onClick={(open, url) => {
                                                handleMedia(open, url)
                                            }}
                                        />
                                        <div style={{ opacity: !visibleNav || mediaPopup.open ? 0 : 1 }} className={style.main_hotspots}>
                                            <Hotspots
                                                hotspots={productGroups[selectedGroupIndex].hotspots}
                                                onClick={(media: string[]) => {
                                                    handleMedia(true, media)
                                                }}
                                                language={language}
                                                canvasImg={canvasImg}
                                            />
                                        </div>
                                    </>
                                }
                                <MediaPopup
                                    type="video"
                                    media={mediaPopup.url}
                                    open={mediaPopup.open}
                                    onClick={handleMedia}
                                />
                            </div>
                        </div>
                    </>}
            </div >

        </>
    );
}

export default Main;