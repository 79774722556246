import { HotspotProp, ProductGroupsProp, GroupInfoElementProp, GroupInfoVideoElementProp } from "./datastructureV2";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Lavo2_1_MidHeightCabinet: HotspotProp = {
    coordinates: [33.7, 50],
    media: [
        "/images/details/sys20_Lavo2_1/MidHeightCabinet/IMG_0027.jpg",
        "/videos/sys20_Lavo2_1/MidHeightCabinet/open.mp4"
    ],
    content: [
        {
            language: "de",
            title: "Halbhoher Schrank",
            descr: "SGCL101",
        },
        {
            language: "en",
            title: "Mid height cabinet",
            descr: "SGCL101",
        },
        {
            language: "fr",
            title: "Mi-Colonne",
            descr: "SGCL101",
        },
        {
            language: "nl",
            title: "Halfhohe kast",
            descr: "SGCL101",
        },
    ],
};

export const sys20_Lavo2_1_MirrorCabinet: HotspotProp = {
    coordinates: [71, 23],
    media: [
        "/images/details/sys20_Lavo2_1/MirrorCabinet/IMG_0011.jpg",
        "/videos/sys20_Lavo2_1/MirrorCabinet/dimmen.mp4"
    ],
    content: [
        {
            language: "de",
            title: "Spiegelschrank mit dimmbarer LED-Beleuchtung",
            descr: "und Lichttemperatur-Steuerung \nSPOW102",
        },
        {
            language: "en",
            title: "Mirror cabinet with LED-light, dimmable",
            descr: "and selection: cold/warm white \nSPOW102",
        },
        {
            language: "fr",
            title: "Armoire de toilette avec eclairage LED avec variateur,",
            descr: "et variateur de la température de l'éclairage \nSPOW102",
        },
        {
            language: "nl",
            title: "Spiegelkast met LED-verlichting, dimbaar",
            descr: "en lichtkleur-dimmer \nSPOW102",
        },
    ],
};

export const sys20_Lavo2_1_MineralCastWashbasin: HotspotProp = {
    coordinates: [77, 66],
    media: [
        "/images/details/sys20_Lavo2_1/MineralCastWashbasin/IMG_0014.jpg",
        "/videos/sys20_Lavo2_1/MineralCastWashbasin/open.mp4"
    ],
    content: [
        {
            language: "de",
            title: "Mineralguss-Waschtisch",
            descr: 'inkl. Waschtischunterschrank \nmit vertikaler Griffleiste \nFrontfarbe: Eisblau Softmatt \nGriff: Schwarz G0227 \nSFZS102',
        },
        {
            language: "en",
            title: "Mineral cast washbasin",
            descr: 'incl. vanity unit \nwith handle and pleat-effect structure \nFrontcolour: Ice-blue soft mat \nHandle: Black G0227 \nSFZS102',
        },
        {
            language: "fr",
            title: "Plan de toilette en pierre de synthèse",
            descr: 'incl. meuble sous-vasque \nprise de main vertical \nCouleur de façade: Bleu azur doux mat \nPoignée: Noir G0227 \nSFZS102',
        },
        {
            language: "nl",
            title: "Wastafel mineraalsteen",
            descr: 'incl. wastafelonderkast \nverticale greeplijst \nFrontkleur: Ijsblauw zacht mat \nGreep: Zwart G0227 \nSFZS102',
        },
    ],
};

//// Equal in all Fiomu-Products
export const sys20_Lavo2_productOverview: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/productOverview/PU_Lavo 2.0_DE.pdf',
        title: "Produktdetails",
    },
    {
        language: "en",
        url: '/productOverview/PU_Lavo 2.0_EN.pdf',
        title: "Product details",
    },
    {
        language: "fr",
        url: '/productOverview/PU_Lavo 2.0_FR.pdf',
        title: "Détails du produit",
    },
    {
        language: "nl",
        url: '/productOverview/PU_Lavo 2.0_NL.pdf',
        title: "Productdetails",
    },
];

//// Equal in all Fiomu-Products
export const sys20_Lavo2_catalogue: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/catalog/KA_Lavo2.0_DE.pdf',
        title: "Katalog",
    },
    {
        language: "en",
        url: '/catalog/KA_Lavo2.0_DE.pdf',
        title: "Catalogue",
    },
    {
        language: "fr",
        url: '/catalog/KA_Lavo2.0_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "nl",
        url: '/catalog/KA_Lavo2.0_DE.pdf',
        title: "Katalog",
    },
];

export const sys20_Lavo2_1_productVideo: GroupInfoVideoElementProp = {
    url: [],
    title: groupInfo_productVideo,
};

export const sys20_Lavo2_1_assemblyVideo: GroupInfoVideoElementProp = {
    url: [],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys20_Lavo2_1: ProductGroupsProp = {
    id: 1,
    prevViewed: false,
    image:  "/images/details/sys20_Lavo2_1/IMG_0001.jpg",
    productGroupName: "sys20 Lavo 2.0",
    videos: [
        "/videos/sys20_Lavo2_1/Moebelstueck14_in.mp4",
        "/videos/sys20_Lavo2_1/Moebelstueck14_out.mp4"
    ],
    descr: "Lavo 2.0",
    hotspots: [
        sys20_Lavo2_1_MidHeightCabinet,
        sys20_Lavo2_1_MirrorCabinet,
        sys20_Lavo2_1_MineralCastWashbasin,
    ],
    groupInfo: {
        productOverview: sys20_Lavo2_productOverview,
        catalog: sys20_Lavo2_catalogue,
        productVideo: sys20_Lavo2_1_productVideo,
        assemblyVideo: sys20_Lavo2_1_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Lavo2_1/",
    },
    wall: "D1"
};