import * as React from 'react';
import style from './productGroup.module.scss';
import VisibilityIcon from '@material-ui/icons/Visibility';
import lozad from 'lozad';


export interface ProductGroupProps {
    image: string;
    prevViewed: boolean;
    descr: string;
    isSelected: boolean
    onClick: () => void;
}

function ProductGroup({ image, prevViewed, descr, isSelected, onClick }: ProductGroupProps) {
    const observer = lozad();
    observer.observe();


    return (
        <div className={isSelected ? style.productGroup_selected : style.productGroup} onClick={onClick}>
            <div className={style.productGroup_container}>
                <img className='lozad' data-placeholder-background="rgba( #4C4C4C, 0.4)" src={image} alt={image} loading="lazy" />
                <VisibilityIcon style={{ opacity: isSelected ? 1 : prevViewed ? 0.5 : 0 }} />
                <span>{descr}</span>
            </div>
        </div>
    );
}

export default ProductGroup;