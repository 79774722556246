import { HotspotProp, ProductGroupsProp, GroupInfoVideoElementProp, } from "./datastructureV2";
import { sys20_Max_productOverview, sys20_Max_catalogue } from "./sys20_Max1";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Max2_Mirror: HotspotProp = {
    coordinates: [50.5, 17],
    media: [
        "/images/details/sys20_Max2/Mirror/max_700_eiche_spiegel_farblicht_2 Kopie.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegel mit LED-Ambientebeleuchtung",
            descr: "SIJP060",
        },
        {
            language: "en",
            title: "Mirror with LED ambiance light",
            descr: "SIJP060",
        },
        {
            language: "fr",
            title: "Miroir avec éclairage LED",
            descr: "SIJP060",
        },
        {
            language: "nl",
            title: "Lichtspiegel met indirecte LED-verlichting",
            descr: "SIJP060",
        },
    ],
};

export const sys20_Max2_CeramicWashbasin: HotspotProp = {
    coordinates: [50.5, 63],
    media: [
        "/images/details/sys20_Max2/CeramicWashbasin/max_700_eiche_stand_wt.jpg",
        "/videos/sys20_Max2/CeramicWashbasin/open.mp4",
        "/videos/sys20_Max2/CeramicWashbasin/close.mp4",
    ],
    content: [
        {
            language: "de",
            title: "Keramik-Waschtisch",
            descr: "inkl. Waschtischunterschrank \nFrontfarbe: Asteiche Natur \nSGJV070",
        },
        {
            language: "en",
            title: "Ceramic washbasin",
            descr: "incl. vanity unit \nFrontcolour: Natural knotty oak \nSGJV070",
        },
        {
            language: "fr",
            title: "Plan de toilette en céramique",
            descr: "incl. meuble sous-vasque \nCouleur de façade: Chêne nature noueux \nSGJV070",
        },
        {
            language: "nl",
            title: "Wastafel keramiek",
            descr: "incl. wastafelonderkast \nFrontkleur: Eiken natuur knoestig \nSGJV070",
        },
    ],
};

export const sys20_Max2_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Max1/productVideo/max.mp4"],
    title: groupInfo_productVideo,
};

export const sys20_Max2_assemblyVideo: GroupInfoVideoElementProp = {
    url: [
        "/videos/sys20_Max1/montageVideo/max700_sps_montagefilm.mp4",
        "/videos/sys20_Max1/montageVideo/max700_wtu_montagefilm.mp4",
    ],
    title: groupInfo_assemblyVideo,
}

///////////// ProductGroup

export const sys20_Max2: ProductGroupsProp = {
    id: 4,
    prevViewed: false,
    image: "/images/details/sys20_Max2/IMG_0001.jpg",
    productGroupName: "sys20_Max2",
    videos: [
        "/videos/sys20_Max2/Moebelstueck6_in.mp4",
        "/videos/sys20_Max2/Moebelstueck6_out.mp4"
    ],
    descr: "Max",
    hotspots: [
        sys20_Max2_Mirror,
        sys20_Max2_CeramicWashbasin,
    ],
    groupInfo: {
        productOverview: sys20_Max_productOverview,
        catalog: sys20_Max_catalogue,
        productVideo: sys20_Max2_productVideo,
        assemblyVideo: sys20_Max2_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Max2/",
    },
    wall: "B2"
};