import { GroupInfoVideoElementProp, HotspotProp, ProductGroupsProp } from "./datastructureV2";
import { sys10_Fiumo_catalogue, sys10_Fiumo_productOverview } from "./sys10_Fiumo_1";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys10_Fiumo_2_IlluminatedMirror: HotspotProp = {
    coordinates: [50, 21],
    media:[
        "/images/details/sys10_Fiumo_2/IlluminatedMirror/IMG_0008.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegel mit Beleuchtung",
            descr: "inkl. Spiegelheizung \nSIJV045",
        },
        {
            language: "en",
            title: "Illuminated mirror",
            descr: "incl. mirror heating \nSIJV045",
        },
        {
            language: "fr",
            title: "Miroir avec éclairage LED",
            descr: "incl. anti-buée \nSIJV045",
        },
        {
            language: "nl",
            title: "Lichtspiegel",
            descr: "incl. spiegelverwarming \nSIJV045",
        },
    ],
};

export const sys10_Fiumo_2_MineralCastWashbasin: HotspotProp = {
    coordinates: [50, 75],
    media:[
        "/images/details/sys10_Fiumo_2/MineralCastWashbasin/IMG_0006.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Mineralguss-Waschtisch",
            descr: 'inkl. Waschtischunterschrank mit Griffleiste und Plisséefront \nFrontfarbe: Tectona Dekor Zimt \nGriff: Schwarz Matt \nSGGV047',
        },
        {
            language: "en",
            title: "Mineral cast washbasin",
            descr: "incl. vanity unit \nwith handle and pleat-effect structure \nFrontcolour: Tectona cinnamon decor \nHandle: Black matt \nSGGV047",
        },
        {
            language: "fr",
            title: "Plan de toilette en pierre de synthèse",
            descr: "incl. meuble sous-vasque \navec prise de main et façade plissée \nCouleur de façade: Tectona décor Cannelle \nPoignée: Noir mat \nSGGV047",
        },
        {
            language: "nl",
            title: "Wastafel mineraalsteen",
            descr: "incl. wastafelonderkast \nmet greeplijst en plooifront \nFrontkleur: Teakhout decor kaneel \nGreep: Zwart mat \nSGGV047",
        },
    ],
};

export const sys10_Fiumo_2_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys10_Fiumo_1/productVideo/fiumo.mp4"],
    title: groupInfo_productVideo,
};

export const sys10_Fiumo_2_assemblyVideo: GroupInfoVideoElementProp = {
    url: [
        "/videos/sys10_Fiumo_1/montageVideo/fiumo_sps_montagefilm.mp4",
        "/videos/sys10_Fiumo_1/montageVideo/fiumo_wtu_montagefilm.mp4",
    ],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys10_Fiumo_2: ProductGroupsProp = {
    id: 2,
    prevViewed: false,
    image: "/images/details/sys10_Fiumo_2/IMG_0002.jpg",
    productGroupName: "sys10_Fiumo2",
    videos: [
        "/videos/sys10_Fiumo_2/Moebelstueck2_in.mp4",
        "/videos/sys10_Fiumo_2/Moebelstueck2_out.mp4"
    ],
    descr: "Fiumo",
    hotspots: [
        sys10_Fiumo_2_IlluminatedMirror,
        sys10_Fiumo_2_MineralCastWashbasin,
    ],
    groupInfo: {
        productOverview: sys10_Fiumo_productOverview,
        catalog: sys10_Fiumo_catalogue,
        productVideo: sys10_Fiumo_2_productVideo,
        assemblyVideo: sys10_Fiumo_2_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys10_Fiumo_2/",
    },
    wall: "A1",
};