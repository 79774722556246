import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { ProductGroupsProp } from '../../../datastructure/datastructureV2';
import ProductGroup from '../productGroup/productGroup';
import style from './sidebar.module.scss'


export interface SidebarProps {
    productGroups: Array<ProductGroupsProp>;
    selectedGroupIndex: number | null;
    onClick: (index: number, pG: ProductGroupsProp) => void;
}

interface SortProductsProps {
    productGroups: ProductGroupsProp[];
    onClick: (index: number, pG: ProductGroupsProp) => void;
    selectedGroupIndex: number | null;
}

interface contentProps {
    category: string | undefined;
    products: any[];
    descr?: string;
}

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

function SortProducts({ productGroups, selectedGroupIndex, onClick }: SortProductsProps) {
    const filteredFiumo = productGroups.filter((product) => product.descr === "Fiumo");
    const filteredMax = productGroups.filter((product) => product.descr === "Max");
    const filteredLin20 = productGroups.filter((product) => product.descr === "Lin20");
    const filteredLavo = productGroups.filter((product) => product.descr === "Lavo 2.0");
    const filteredRl40 = productGroups.filter((product) => product.descr === "rl40");
    const filteredEqioSmart = productGroups.filter((product) => product.descr === "Eqio smart");
    const filteredFree = productGroups.filter((product) => product.descr === "Free");

    var content: contentProps[] = [];

    if (filteredFiumo.length > 0) {
        content.push({
            category: filteredFiumo[0].productGroupName,
            products: filteredFiumo,
            descr: filteredFiumo[0].descr,
        })
    }
    if (filteredEqioSmart.length > 0) {
        content.push({
            category: filteredEqioSmart[0].productGroupName,
            products: filteredEqioSmart,
            descr: filteredEqioSmart[0].descr,
        })
    }
    if (filteredFree.length > 0) {
        content.push({
            category: filteredFree[0].productGroupName,
            products: filteredFree,
            descr: filteredFree[0].descr,
        })
    }
    if (filteredMax.length > 0) {
        content.push({
            category: filteredMax[0].productGroupName,
            products: filteredMax,
            descr: filteredMax[0].descr,
        })
    }
    if (filteredLin20.length > 0) {
        content.push({
            category: filteredLin20[0].productGroupName,
            products: filteredLin20,
            descr: filteredLin20[0].descr,
        })
    }
    if (filteredLavo.length > 0) {
        content.push({
            category: filteredLavo[0].productGroupName,
            products: filteredLavo,
            descr: filteredLavo[0].descr,
        })
    }
    if (filteredRl40.length > 0) {
        content.push({
            category: filteredRl40[0].productGroupName,
            products: filteredRl40,
            descr: filteredRl40[0].descr,
        })
    }

    var productIndex = -1;

    return (
        <div className={style.category}>
            {
                content.map((category, categoryIndex) => (
                    <div className={style.categoryContainer} key={category.category + "_" + categoryIndex}>
                        <h5>{category.category}</h5>
                        {
                            category.category &&
                            category.products.map((pG) => {
                                productIndex += 1;
                                let currPGIndex = productIndex;
                                return (
                                    <ProductGroup
                                        image={pG.image}
                                        prevViewed={pG.prevViewed}
                                        descr={pG.descr}
                                        key={"key_" + pG.descr + "_" + productIndex}
                                        isSelected={selectedGroupIndex === currPGIndex}
                                        onClick={() => onClick(currPGIndex, pG)}
                                    />
                                )
                            })

                        }
                    </div>
                ))
            }

        </div>
    )

}

function Sidebar({ productGroups, selectedGroupIndex, onClick }: SidebarProps) {
    const windowDimension = { width: document.documentElement.clientWidth, height: document.documentElement.clientHeight };
    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId: any = null;
        const resizeListener = () => {
          // prevent execution of previous setTimeout
          clearTimeout(timeoutId);
          // change width from the state object after 150 milliseconds
          timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);
    
        // clean up function
        return () => {
          // remove resize listener
          window.removeEventListener('resize', resizeListener);
        }
      }, [])

    return (
        <div className={style.sidebar_container}>
            <div className={width >= 950  ? style.sidebar : style.sidebar_tabletView}>
                {width >= 950 && 
                    <div className={style.sidebar_logoContainer}>
                        <div className={style.sidebar_logo}>
                            <a onClick={() => window.location.reload()}>
                                <img src={"/images/logo.png"} alt="logo" />
                            </a>
                        </div>
                        <div className={style.sidebar_logoContainer_line} />
                    </div>
                }
                <div
                    className={width >= 950  ? style.sidebar_productGroups : style.sidebar_productGroups_tabletView}>
                    <SortProducts
                        productGroups={productGroups}
                        selectedGroupIndex={selectedGroupIndex}
                        onClick={(index, pG) => { onClick(index, pG) }}
                    />
                </div>
            </div>
        </div>
    );
}

export default memo(Sidebar);