import { GroupInfoVideoElementProp, HotspotProp, ProductGroupsProp } from "./datastructureV2";
import { sys20_Lin20_productOverview, sys20_Lin20_catalogue } from "./sys20_Lin20_1";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Lin20_4_Mirror: HotspotProp = {
    coordinates: [57.3, 22],
    media: [
        "/images/details/sys20_Lin20_4/Mirror/IMG_0002.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegelschrank mit LED-Beleuchtung",
            descr: "SPQK160",
        },
        {
            language: "en",
            title: "Mirror cabinet with LED-lighting",
            descr: "SPQK160",
        },
        {
            language: "fr",
            title: "Miroir avec cadre et éclairage LED",
            descr: "SPQK160",
        },
        {
            language: "nl",
            title: "Lichtspiegel met LED-verlichting",
            descr: "SPQK160",
        },
    ],
};

export const sys20_Lin20_4_MineralCastWashbasin: HotspotProp = {
    coordinates: [35, 70],
    media: [
        "/images/details/sys20_Lin20_4/MineralCastWashbasin/lin20_eiche-merino_detail_wt.jpg",
        "/videos/sys20_Lin20_4/MineralCastWashbasin/open.mp4",
        "/videos/sys20_Lin20_4/MineralCastWashbasin/close.mp4",
    ],
    content: [
        {
            language: "de",
            title: "Keramik-Waschtisch",
            descr: "inkl. Waschtischunterschrank \nFrontfarbe: Eiche Dekor Merino \nSGHB063",
        },
        {
            language: "en",
            title: "Mineral cast washbasin",
            descr: "incl. vanity unit \nFrontcolour: Merino oak decor \nSGHB063",
        },
        {
            language: "fr",
            title: "Plan de toilette en céramique",
            descr: "incl. meuble sous-vasque \nCouleur de façade: Chêne décor merino \nSGHB063",
        },
        {
            language: "nl",
            title: "Wastafel keramiek",
            descr: "incl. wastafelonderkast \nFrontkleur: Eiken decor merino \nSGHB063",
        },
    ],
};

export const sys20_Lin20_4_BaseUnit: HotspotProp = {
    coordinates: [51, 70],
    media: [
        "/images/details/sys20_Lin20_4/BaseUnit/IMG_0015.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Unterschrank",
            descr: "Frontfarbe: Basaltgrau Matt \nUSIS040",
        },
        {
            language: "en",
            title: "Base unit",
            descr: "Frontcolour: Ice-blue soft matt \nUSIS040",
        },
        {
            language: "fr",
            title: "Meubles bas",
            descr: "Couleur de façade: Bleu azur doux mat \nUSIS040",
        },
        {
            language: "nl",
            title: "Onderkast",
            descr: "Frontkleur: Ijsblauw zacht mat \nUSIS040",
        },
    ],
}

export const sys20_Lin20_4_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Lin20_1/productVideo/lin_20.mp4"],
    title: groupInfo_productVideo,
};

export const sys20_Lin20_4_assemblyVideo: GroupInfoVideoElementProp = {
    url: [],
    title: groupInfo_assemblyVideo,
}

///////////// ProductGroup
export const sys20_Lin20_4: ProductGroupsProp = {
    id: 11,
    prevViewed: false,
    image: "/images/details/sys20_Lin20_4/IMG_0001.jpg",
    productGroupName: "sys20_Lin20_4",
    videos: [
        "/videos/sys20_Lin20_4/Moebelstueck13_in.mp4",
        "/videos/sys20_Lin20_4/Moebelstueck13_out.mp4",
    ],
    descr: "Lin20",
    hotspots: [
        sys20_Lin20_4_Mirror,
        sys20_Lin20_4_MineralCastWashbasin,
        sys20_Lin20_4_BaseUnit,
    ],
    groupInfo: {
        productOverview: sys20_Lin20_productOverview,
        catalog: sys20_Lin20_catalogue,
        productVideo: sys20_Lin20_4_productVideo,
        assemblyVideo: sys20_Lin20_4_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Lin20_4/",
    },
    wall: "C2"
};