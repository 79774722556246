import { GroupInfoElementProp, GroupInfoVideoElementProp, HotspotProp, ProductGroupsProp } from "./datastructureV2";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

const sys10_Eqio_smart_Mirror: HotspotProp = {
    coordinates: [50.5, 25],
    media: [
        "/images/details/sys10_Eqio_smart/Mirror/IMG_0010.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegel mit LED-Beleuchtung",
            descr: "inkl. Spiegelheizung \nSIIX100",
        },
        {
            language: "en",
            title: "Illuminated mirror",
            descr: "incl. mirror heating \nSIJN075"
        },
        {
            language: "fr",
            title: "Miroir avec éclairage LED",
            descr: "incl. anti-buée \nSIIX100",
        },
        {
            language: "nl",
            title: "Lichtspiegel",
            descr: "incl. spiegelverwarming \nSIIX100",
        },
    ],
};

const sys10_Eqio_smart_Washbasin: HotspotProp = {
    coordinates: [50.5, 64],
    media: [
        "/images/details/sys10_Eqio_smart/Washbasin/IMG_0007.jpg",
        "/images/details/sys10_Eqio_smart/Washbasin/IMG_0018.jpg",
        "/videos/sys10_Eqio_smart/Washbasin/open.mp4",
        "/videos/sys10_Eqio_smart/Washbasin/close.mp4",
    ],
    content: [
        {
            language: "de",
            title: "Keramik-Waschtisch",
            descr: "inkl. Waschtischunterschrank \nFrontfarbe: Eiche Dekor Naturell \nGriff: Schwarz Matt \nSFSR103",
        },
        {
            language: "en",
            title: "Ceramic washbasin",
            descr: "incl. vanity unit \nFrontcolour: Natural oak decor \nHandle: Black Matt \nSFSR103"
        },
        {
            language: "fr",
            title: "Plan de toilette en céramique",
            descr: "incl. meuble sous-vasque \nCouleur de façade: Chêne décor naturel \nPoignée: Noir mat \nSFSR103",
        },
        {
            language: "nl",
            title: "Wastafel keramiek",
            descr: "incl. wastafelonderkast \nFrontkleur: Eiken decor natuur \nGreep: Zwart mat \nSFSR103",
        },
    ],
};

//// Equal in all Fiomu-Products
export const sys10_Eqio_smart_productOverview: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/productOverview/PU_EqioSmart_EN.pdf',
        title: "Produktdetails",
    },
    {
        language: "en",
        url: '/productOverview/PU_EqioSmart_EN.pdf',
        title: "Product details",
    },
    {
        language: "fr",
        url: '/productOverview/PU_EqioSmart_FR.pdf',
        title: "Détails du produit",
    },
    {
        language: "nl",
        url: '/productOverview/PU_EqioSmart_NL.pdf',
        title: "Productdetails",
    },

];

//// Equal in all Fiomu-Products
export const sys10_Eqio_smart_catalogue: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/catalog/KA_EqioSmart_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "en",
        url: '/catalog/KA_EqioSmart_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "fr",
        url: '/catalog/KA_EqioSmart_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "nl",
        url: '/catalog/KA_EqioSmart_FR_Belux.pdf',
        title: "Katalog",
    },
];

export const sys10_Eqio_smart_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys10_Eqio_smart/productVideo/eqio blauer engel.mp4"],
    title: groupInfo_productVideo,
};

export const sys10_Eqio_smart_assemblyVideo: GroupInfoVideoElementProp = {
    url: [
        "/videos/sys10_Eqio_smart/montageVideo/equio_sps_montagefilm.mp4",
        "/videos/sys10_Eqio_smart/montageVideo/equio_wtu_montagefilm.mp4"
    ],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys10_Eqio_smart: ProductGroupsProp = {
    id: 1,
    prevViewed: false,
    image: "/images/details/sys10_Eqio_smart/IMG_0001.jpg",
    productGroupName: "sys10 Eqio smart",
    videos: [
        "/videos/sys10_Eqio_smart/Moebelstueck4_in.mp4",
        "/videos/sys10_Eqio_smart/Moebelstueck4_out.mp4"
    ],
    descr: "Eqio smart",
    hotspots: [
        sys10_Eqio_smart_Mirror,
        sys10_Eqio_smart_Washbasin,
    ],
    groupInfo: {
        productOverview: sys10_Eqio_smart_productOverview,
        catalog: sys10_Eqio_smart_catalogue,
        productVideo: sys10_Eqio_smart_productVideo,
        assemblyVideo: sys10_Eqio_smart_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys10_Eqio_smart/",
    },
    wall: "A2",
};