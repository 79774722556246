import { HotspotProp, ProductGroupsProp, GroupInfoVideoElementProp, } from "./datastructureV2";
import { sys20_Lin20_productOverview, sys20_Lin20_catalogue } from "./sys20_Lin20_1";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys20_Lin20_3_Mirror: HotspotProp = {
    coordinates: [50.5, 27],
    media: [
        "/images/details/sys20_Lin20_3/Mirror/IMG_0022.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Spiegel mit LED-Rahmen",
            descr: "SIDG090",
        },
        {
            language: "en",
            title: "Illuminated mirror with LED-lighting",
            descr: "SIDG090",
        },
        {
            language: "fr",
            title: "Miroir avec cadre et éclairage LED",
            descr: "SIDG090",
        },
        {
            language: "nl",
            title: "Lichtspiegel met LED-verlichting",
            descr: "SIDG090",
        },
    ],
};

export const sys20_Lin20_3_MineralCastWashbasin: HotspotProp = {
    coordinates: [56.5, 67],
    media: [
        "/images/details/sys20_Lin20_3/MineralCastWashbasin/lin20_weissmatt_sideboard_detail.jpg",
        "/images/details/sys20_Lin20_3/MineralCastWashbasin/lin20_weissmatt_wt_02.jpg",
        "/videos/sys20_Lin20_3/MineralCastWashbasin/open.mp4",
        "/videos/sys20_Lin20_3/MineralCastWashbasin/close.mp4",
    ],
    content: [
        {
            language: "de",
            title: "Mineralguss-Waschtisch",
            descr: "inkl. Waschtischunterschrank \nFrontfarbe: Weiß Matt \nSGHX122",
        },
        {
            language: "en",
            title: "Mineral cast washbasin",
            descr: "incl. vanity unit \nFrontcolour: White matt \nSGHX122",
        },
        {
            language: "fr",
            title: "Plan de toilette en pierre de synthèse",
            descr: "incl. meuble sous-vasque \nCouleur de façade: Blanc mat \nSGHX122",
        },
        {
            language: "nl",
            title: "Wastafel mineraalsteen",
            descr: "incl. wastafelonderkast \nFrontkleur: Wit mat \nSGHX122",
        },
    ],
};

export const sys20_Lin20_3_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Lin20_1/productVideo/lin_20.mp4"],
    title: groupInfo_productVideo,
};

export const sys20_Lin20_3_assemblyVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys20_Lin20_1/montageVideo/lin-20_lsp_montagefilm.mp4"],
    title: groupInfo_assemblyVideo,
}



///////////// ProductGroup
export const sys20_Lin20_3: ProductGroupsProp = {
    id: 10,
    prevViewed: false,
    image: "/images/details/sys20_Lin20_3/IMG_0001.jpg",
    productGroupName: "sys20_Lin20_3",
    videos: [
        "/videos/sys20_Lin20_3/Moebelstueck12_in.mp4",
        "/videos/sys20_Lin20_3/Moebelstueck12_out.mp4",
    ],
    descr: "Lin20",
    hotspots: [
        sys20_Lin20_3_Mirror,
        sys20_Lin20_3_MineralCastWashbasin,
    ],
    groupInfo: {
        productOverview: sys20_Lin20_productOverview,
        catalog: sys20_Lin20_catalogue,
        productVideo: sys20_Lin20_3_productVideo,
        assemblyVideo: sys20_Lin20_3_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys20_Lin20_3/",
    },
    wall: "C2"
};