import { sys10_Eqio_smart } from "./sys10_Eqio_smart";
import { sys10_Fiumo_1 } from "./sys10_Fiumo_1";
import { sys10_Fiumo_2 } from "./sys10_Fiumo_2";
import { sys10_Free } from "./sys10_Free";
import { sys20_Lavo2_1 } from "./sys20_Lavo2_1";
import { sys20_Lavo2_2 } from "./sys20_Lavo2_2";
import { sys20_Lin20_1 } from "./sys20_Lin20_1";
import { sys20_Lin20_2 } from "./sys20_Lin20_2";
import { sys20_Lin20_3 } from "./sys20_Lin20_3";
import { sys20_Lin20_4 } from "./sys20_Lin20_4";
import { sys20_Max1 } from "./sys20_Max1";
import { sys20_Max2 } from "./sys20_Max2";
import { sys20_Max3 } from "./sys20_Max3";
import { sys20_Max4 } from "./sys20_Max4";
import { sys20_Max5 } from "./sys20_Max5";
import { sys30_rl40 } from "./sys30_rl40";

export interface DataProps {
    region: string;
    productGroups: Array<ProductGroupsProp>;
}

export interface ProductGroupsProp {
    id: number;
    productGroupName?: string;
    prevViewed: boolean;
    image: string;
    videos: [string, string];
    descr: string;
    hotspots: Array<HotspotProp>;
    groupInfo: GroupInfoProp;
    _3D: _3DProp;
    wall: string;
}

export interface ContentProp {
    language: string;
    title: string;
    descr: string;
}

export interface HotspotProp {
    coordinates: Array<number>; //in percent
    content: Array<ContentProp>;
    media: string[];
    language?: string;
    // eslint-disable-next-line no-empty-pattern
    onClick?: () => void;
}

export interface GroupInfoElementProp {
    url: string;
    title: string;
    language: string;
}

export interface GroupInfoVideoElementProp {
    url: string[];
    title: Array<GroupInfoVideoLanguageProp>;
}

export interface GroupInfoVideoLanguageProp {
    language: string;
    name: string;
}

export interface GroupInfoProp {
    productOverview: Array<GroupInfoElementProp>;
    catalog: Array<GroupInfoElementProp>;
    productVideo: GroupInfoVideoElementProp;
    assemblyVideo: GroupInfoVideoElementProp;
}

export interface _3DProp {
    frames: number;
    url: string;
}


export const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

export const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];


/////////////////////////////////////////////////////
/* The order of the productGroups has to be:
    Fiumo,
    Max,
    Eqio-Smart,
    Free,
    Max,
    Lin20,
    Lavo.
    rl40
*/

export const dataV2: Array<DataProps> = [
    {
        region: "DE",
        productGroups: [
            sys10_Fiumo_1,
            sys10_Fiumo_2,
            sys20_Max1,
            sys20_Max2,
            sys20_Max3,
            sys20_Max4,
            sys20_Max5,
            sys20_Lin20_1,
            sys20_Lin20_2,
            sys20_Lin20_3,
            sys20_Lin20_4,
            sys20_Lavo2_1,
            sys20_Lavo2_2,
            sys30_rl40,
        ],
    },
    {
        region: "AT",
        productGroups: [
            sys10_Fiumo_1,
            sys10_Fiumo_2,
            sys20_Max1,
            sys20_Max2,
            sys20_Max3,
            sys20_Max4,
            sys20_Max5,
            sys20_Lin20_1,
            sys20_Lin20_2,
            sys20_Lin20_3,
            sys20_Lin20_4,
            sys20_Lavo2_1,
            sys20_Lavo2_2,
            sys30_rl40,
        ]
    },
    {
        region: "CH",
        productGroups: [
            sys10_Fiumo_1,
            sys10_Fiumo_2,
            sys20_Max1,
            sys20_Max2,
            sys20_Max3,
            sys20_Max4,
            sys20_Max5,
            sys20_Lin20_1,
            sys20_Lin20_2,
            sys20_Lin20_3,
            sys20_Lin20_4,
            sys20_Lavo2_1,
            sys20_Lavo2_2,
            sys30_rl40,
        ]
    },
    {
        region: "BE",
        productGroups: [
            sys10_Fiumo_1,
            sys10_Fiumo_2,
            sys10_Eqio_smart,
            sys20_Max1,
            sys20_Max2,
            sys20_Max3,
            sys20_Max4,
            sys20_Max5,
            sys20_Lin20_1,
            sys20_Lin20_2,
            sys20_Lin20_3,
            sys20_Lin20_4,
            sys20_Lavo2_1,
            sys20_Lavo2_2,
            sys30_rl40,
        ]
    },
    {
        region: "NL",
        productGroups: [
            sys10_Fiumo_1,
            sys10_Fiumo_2,
            sys10_Eqio_smart,
            sys20_Max1,
            sys20_Max2,
            sys20_Max3,
            sys20_Max4,
            sys20_Max5,
            sys20_Lin20_1,
            sys20_Lin20_2,
            sys20_Lin20_3,
            sys20_Lin20_4,
            sys20_Lavo2_1,
            sys20_Lavo2_2,
            sys30_rl40,
        ]
    },
    {
        region: "FR",
        productGroups: [
            sys10_Fiumo_1,
            sys10_Fiumo_2,
            sys10_Eqio_smart,
            sys10_Free,
            sys20_Max1,
            sys20_Max2,
            sys20_Max3,
            sys20_Max4,
            sys20_Max5,
            sys20_Lin20_1,
            sys20_Lin20_2,
            sys20_Lin20_3,
            sys20_Lin20_4,
            sys20_Lavo2_1,
            sys20_Lavo2_2,
            sys30_rl40,
        ]
    }
];
