import * as React from 'react';
import style from './infoComponent.module.scss';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import { memo } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { GroupInfoProp } from '../../../datastructure/datastructureV2';
import ListIcon from '@material-ui/icons/List';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useState, useEffect } from 'react';

interface InfoComponentProps extends GroupInfoProp {
    language: string;
    onClick: (open: boolean, url: string[]) => void;
}


function InfoComponent(props: InfoComponentProps) {
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [productOverviewIndex, setProductOverviewIndex] = useState<number>(0);
    const [catalogIndex, setCatalogIndex] = useState<number>(0);
    const [productVideoIndex, setProductVideoIndex] = useState<number>(0);
    const [assemblyVideoIndex, setAssemblyVideoIndex] = useState<number>(0);

    let headerTitle = "Product details";
    switch (props.language) {
        case "de":
            headerTitle = "Details";
            break;
        case "en":
            headerTitle = "Details";
            break;
        case "fr":
            headerTitle = "Détails";
            break;
        case "nl":
            headerTitle = "Details";
            break;
        default:
            headerTitle = "Details";
            break;
    }

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setOpenPopover(true);
    };

    const handlePopoverClose = () => {
        setOpenPopover(false);
    };

    useEffect(() => {
        const productIndex = props.productOverview.findIndex(ele => ele.language === props.language) >= 0 ? props.productOverview.findIndex(ele => ele.language === props.language) : 0;
        setProductOverviewIndex(productIndex);

        const catIndex = props.catalog.findIndex(ele => ele.language === props.language) >= 0 ? props.catalog.findIndex(ele => ele.language === props.language) : 0;
        setCatalogIndex(catIndex);

        const proVidIndext = props.productVideo.title.findIndex(ele => ele.language === props.language) >= 0 ? props.productVideo.title.findIndex(ele => ele.language === props.language) : 0;
        setProductVideoIndex(proVidIndext);

        const assemblyVidIndext = props.assemblyVideo.title.findIndex(ele => ele.language === props.language) >= 0 ? props.assemblyVideo.title.findIndex(ele => ele.language === props.language) : 0;
        setAssemblyVideoIndex(assemblyVidIndext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language]);


    return (
        <div
            className={style.infoComponent}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <Button
                variant="contained"
                size="large"
                aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onClick={handlePopoverOpen}
                className={style.button}
                startIcon={<InfoIcon className={style.buttonIcon} />}
            >
                <span>{headerTitle}</span>
                {openPopover &&
                    <div
                        id="mouse-over-popover"
                        className={style.infoComponent_popover}
                        onMouseLeave={handlePopoverClose}
                    >
                        <List component="nav" aria-label="list">
                            <ListItem button component="a" target="_blank" href={props.productOverview[productOverviewIndex].url}>
                                <ListItemIcon className={style.litsIcon}>
                                    <ListIcon />
                                </ListItemIcon>
                                <ListItemText className={style.listText} primary={props.productOverview[productOverviewIndex].title} />
                            </ListItem>
                            <ListItem button component="a" target="_blank" href={props.catalog[catalogIndex].url}>
                                <ListItemIcon className={style.litsIcon}>
                                    <MenuBookIcon />
                                </ListItemIcon>
                                <ListItemText className={style.listText} primary={props.catalog[catalogIndex].title} />
                            </ListItem>
                            {props.productVideo.url.length > 0 &&
                                <ListItem button onClick={() => props.onClick(true, props.productVideo.url)} >
                                    <ListItemIcon className={style.litsIcon}>
                                        <PlayArrowIcon />
                                    </ListItemIcon>
                                    <ListItemText className={style.listText} primary={props.productVideo.title[productVideoIndex].name} />
                                </ListItem>
                            }
                            {props.assemblyVideo.url.length > 0 &&
                                <ListItem button onClick={() => props.onClick(true, props.assemblyVideo.url)}>
                                    <ListItemIcon className={style.litsIcon}>
                                        <PlayArrowIcon />
                                    </ListItemIcon>
                                    <ListItemText className={style.listText} primary={props.assemblyVideo.title[assemblyVideoIndex].name} />
                                </ListItem>
                            }
                        </List>
                    </div>
                }
            </Button>
        </div>
    );
}

export default memo(InfoComponent);