import { HotspotProp, ProductGroupsProp, GroupInfoElementProp, GroupInfoVideoElementProp, } from "./datastructureV2";

const groupInfo_assemblyVideo = [
    {
        language: "de",
        name: "Montagevideo",
    },
    {
        language: "en",
        name: "Mounting video",
    },
    {
        language: "fr",
        name: "Vidéo de montage",
    },
    {
        language: "nl",
        name: "Montagevideo",
    },
];

const groupInfo_productVideo = [
    {
        language: "de",
        name: "Produktvideo",
    },
    {
        language: "en",
        name: "Product video",
    },
    {
        language: "fr",
        name: "Vidéo de produits",
    },
    {
        language: "nl",
        name: "Productvideo",
    },
];

export const sys30_rl40_Mirror: HotspotProp = {
    coordinates: [57, 21],
    media: [
        "/images/details/sys30_rl40/Mirror/IMG_0011.jpg",
        "/videos/sys30_rl40/Mirror/dimmen.mp4",
    ],
    content: [
        {
            language: "de",
            title: "rl40 VC Spiegelschrank mit Smart-Home-System",
            descr: "SPQY100",
        },
        {
            language: "en",
            title: "rl40 VC mirror cabinet with Smart-Home-System",
            descr: "SPQY100",
        },
        {
            language: "fr",
            title: "rl40 VC armoire de toilette avec Smart-Home-System",
            descr: "SPQY100",
        },
        {
            language: "nl",
            title: "rl40 VC Spiegelkast met Smart-Home-System",
            descr: "SPQY100",
        },
    ],
};

export const sys30_rl40_MineralCastWashbasin: HotspotProp = {
    coordinates: [36, 54],
    media: [
        "/images/details/sys30_rl40/MineralCastWashbasin/IMG_0015.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Mineralguss-Aufsatzwaschtisch",
            descr: "MWIM056",
        },
        {
            language: "en",
            title: "Mineral cast washbasin sit on vessel",
            descr: "MWIM056",
        },
        {
            language: "fr",
            title: "Vasques à poser en pierre de synthèse",
            descr: "MWIM056",
        },
        {
            language: "nl",
            title: "Opzetwaskom mineraalsteen",
            descr: "MWIM056",
        },
    ],
};

export const sys30_rl40_CountertopSolidWood: HotspotProp = {
    coordinates: [61, 61.5],
    media: [
        "/images/details/sys30_rl40/CountertopSolidWood/rl30_sys30_konsole_baumkante_1.jpg",
        "/images/details/sys30_rl40/CountertopSolidWood/rl30_sys30_wt.jpg",
        "/videos/sys30_rl40/CountertopSolidWood/details.mp4",
        "/videos/sys30_rl40/CountertopSolidWood/alexa.mp4",
    ],
    content: [
        {
            language: "de",
            title: "Massivholz-Konsolenplatte",
            descr: 'mit Konsolenträger \nFarbe: Eiche Massiv Natur \nWPDR120 / ACHW003 / ACHW008',
        },
        {
            language: "en",
            title: "Countertop solid wood",
            descr: 'with console base \nColour: Natural oak solid \nWPDR120 / ACHW003 / ACHW008',
        },
        {
            language: "fr",
            title: "Plateau bois massif",
            descr: 'avec console \nFarbe: Chêne nature massif \nWPDR120 / ACHW003 / ACHW008',
        },
        {
            language: "nl",
            title: "Consoleplaat massief hout",
            descr: 'met consoledragers \nKleur: Eiken natuur massief \nWPDR120 / ACHW003 / ACHW008',
        },
    ],
};

export const sys30_rl40_BaseUnit: HotspotProp = {
    coordinates: [69.5, 77],
    media: [
        "/images/details/sys30_rl40/BaseUnit/IMG_0018.jpg",
    ],
    content: [
        {
            language: "de",
            title: "Unterschrank",
            descr: 'Frontfarbe: Sand Matt \nWVOL060',
        },
        {
            language: "en",
            title: "Base unit",
            descr: 'Frontcolour: Sand matt \nWVOL060',
        },
        {
            language: "fr",
            title: "Meuble bas",
            descr: 'Couleur de façade: Sable mat \nWVOL060',
        },
        {
            language: "nl",
            title: "Onderkast",
            descr: 'Frontkleur: Zand mat \nWVOL060',
        },
    ],
};

//// Equal in all sys30_rl40-Products
export const sys30_rl40_productOverview: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/productOverview/PU_rl40_DE.pdf',
        title: "Produktdetails",
    },
    {
        language: "en",
        url: '/productOverview/PU_rl40_EN.pdf',
        title: "Product details",
    },
    {
        language: "fr",
        url: '/productOverview/PU_rl40_FR.pdf',
        title: "Détails du produit",
    },
    {
        language: "nl",
        url: '/productOverview/PU_rl40_NL.pdf',
        title: "Productdetails",
    },
];

//// Equal in all sys30_rl40-Products
export const sys30_rl40_catalogue: Array<GroupInfoElementProp> = [
    {
        language: "de",
        url: '/catalog/KA_rl40_DE.pdf',
        title: "Katalog",
    },
    {
        language: "en",
        url: '/catalog/KA_rl40_DE.pdf',
        title: "Catalogue",
    },
    {
        language: "fr",
        url: '/catalog/KA_rl40_FR.pdf',
        title: "Catalogue",
    },
    {
        language: "nl",
        url: '/catalog/KA_rl40_DE.pdf',
        title: "Katalog",
    },
];

export const sys30_rl40_productVideo: GroupInfoVideoElementProp = {
    url: ["/videos/sys30_rl40/productVideo/rc_40.mp4"],
    title: groupInfo_productVideo,
};

export const sys30_rl40_assemblyVideo: GroupInfoVideoElementProp = {
    url: [],
    title: groupInfo_assemblyVideo,
}


///////////// ProductGroup

export const sys30_rl40: ProductGroupsProp = {
    id: 1,
    prevViewed: false,
    image: "/images/details/sys30_rl40/IMG_0001.jpg",
    productGroupName: "sys30 rl40",
    videos: [
        "/videos/sys30_rl40/Moebelstueck16_in.mp4",
        "/videos/sys30_rl40/Moebelstueck16_out.mp4"
    ],
    descr: "rl40",
    hotspots: [
        sys30_rl40_Mirror,
        sys30_rl40_MineralCastWashbasin,
        sys30_rl40_CountertopSolidWood,
        sys30_rl40_BaseUnit
    ],
    groupInfo: {
        productOverview: sys30_rl40_productOverview,
        catalog: sys30_rl40_catalogue,
        productVideo: sys30_rl40_productVideo,
        assemblyVideo: sys30_rl40_assemblyVideo,
    },
    _3D: {
        frames: 45,
        url: "/images/fake3D/sys30_rl40/",
    },
    wall: "D2"
};